/**
 *
 * PenaltyEdit
 *
 */

import { useMemo } from 'react';
import { Box, Flex, Spinner, SystemStyleObject } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageWrapper from 'app/components/PageWrapper';
import PenaltyForm, { PenaltyFormData } from 'app/components/PenaltyForm';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { IRequestPenaltyData, getPenaltyDataById, updatePenaltyData } from 'services/http/penalty';
import { IPenaltyDataResponse } from 'types/penalty';
import {
	API_DEFAULT_ERROR,
	BIMESTERS_ENUM,
	DTA_PHASES_ENUM,
	OTHER_PHASES,
	RESULTER_FILTER_STEPS,
} from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { ResponseErrors } from 'utils/parseErrors';

type IParams = {
	penaltyId: string;
};

const PenaltyEdit = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
		},
		content: {
			mt: '3.75rem',
		},
		spinner: {
			mt: '3.7rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const { penaltyId = '' } = useParams<IParams>();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const { data: defaultValuesData, isLoading: isPenaltiesLoading } = useQuery<
		IPenaltyDataResponse,
		AxiosError<ResponseErrors>,
		IPenaltyDataResponse
	>(['penalty-data', penaltyId], () => getPenaltyDataById(penaltyId), {
		onError: ({ response }) =>
			addToast({ type: 'error', description: response?.data?.message || API_DEFAULT_ERROR, title: 'Tente novamente!' }),
		enabled: !!penaltyId,
	});

	const { mutate: updatePenaltyDataMutate, isLoading: isUpdateLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IRequestPenaltyData
	>(data => updatePenaltyData(data, penaltyId), {
		onSuccess: () => {
			navigate(ROUTES.penalty);
			addToast({ type: 'success', description: 'A penalização foi alterada com sucesso!', title: 'Sucesso!' });
		},
		onError: ({ response }) =>
			addToast({ type: 'error', description: response?.data?.message || API_DEFAULT_ERROR, title: 'Tente novamente!' }),
	});

	const defaultValuesForm: PenaltyFormData = useMemo(() => {
		return (
			{
				id: defaultValuesData?.idPenalizacao,
				bimestre: {
					label: BIMESTERS_ENUM[String(defaultValuesData?.bimestreEnum) as keyof typeof BIMESTERS_ENUM],
					value: String(defaultValuesData?.bimestreEnum),
				},
				clausula: String(defaultValuesData?.clausulaContratual),
				dataLimite: formatDate(defaultValuesData?.prazoResponseDTO?.dataPrazo),
				fase: {
					label: `${
						defaultValuesData?.faseEnum === OTHER_PHASES.value
							? defaultValuesData?.faseAdicional
							: DTA_PHASES_ENUM[String(defaultValuesData?.faseEnum) as keyof typeof DTA_PHASES_ENUM]
					} - 
						${formatDate(defaultValuesData?.prazoResponseDTO.dataPrazo)}`,
					value: Number(defaultValuesData?.idControleDatas),
				},
				pactuado: {
					label: String(
						defaultValuesData?.faseEnum === OTHER_PHASES.value
							? defaultValuesData?.nomePenalizado
							: defaultValuesData?.nomePactuado,
					),
					value: Number(defaultValuesData?.idExternoPactuado),
				},
				pontosPerdidos: {
					label: String(defaultValuesData?.pontosPerdidos),
					value: Number(defaultValuesData?.pontosPerdidos),
				},
				etapa: {
					label: RESULTER_FILTER_STEPS[String(defaultValuesData?.etapaEnum) as keyof typeof RESULTER_FILTER_STEPS],
					value: String(defaultValuesData?.etapaEnum),
				},
				justificativa: String(defaultValuesData?.justificativa),
			} || ({} as PenaltyFormData)
		);
	}, [defaultValuesData]);

	const handleSubmit = (data: PenaltyFormData) => {
		const parsedData: IRequestPenaltyData = {
			idControleDatas: Number(data.fase.value),
			bimestreEnum: String(data.bimestre.value),
			pontosPerdidos: Number(data.pontosPerdidos.value),
			clausulaContratual: data.clausula,
			justificativa: data.justificativa,
			idExternoPactuado: Number(data.pactuado.value),
			nomePactuado: String(data.pactuado.label),
			idUnidade: Number(data?.technicalAreaUnitId),
		};

		updatePenaltyDataMutate(parsedData);
	};

	return (
		<Flex sx={styles.container}>
			{isPenaltiesLoading ? (
				<Box sx={styles?.spinner}>
					<Spinner size="xl" color="#3EA2A2" />
				</Box>
			) : (
				<>
					<PageWrapper title="Editar Penalidade" />

					<Box sx={styles.content}>
						<PenaltyForm
							onSubmit={handleSubmit}
							defaultValues={defaultValuesForm}
							isEditing
							isLoading={isUpdateLoading}
						/>
					</Box>
				</>
			)}
		</Flex>
	);
};

export default PenaltyEdit;
