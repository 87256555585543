/**
 *
 * DataControlExportModal
 *
 */

import TableExportModal from './TableExportModal';
import Select from '../Select';
import { useMemo, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { DataExportModalImg } from 'assets/icons';
import { AxiosError } from 'axios';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { getDataControlTableExportPdf, getPeriodDataPact, getDataControlTableExport } from 'services/http/dateControl';
import { IDateControlExport, IResponseExportDate } from 'types/dateControl';
import { IOption } from 'types/select';
import { IColumn, IRow } from 'types/table';
import {
	API_DEFAULT_ERROR,
	BIMESTER_ENUM_TO_VALUE,
	DATA_CONTROL_STEPS_OPTIONS,
	DTA_CONTROL_ENUM,
	RESULT_EVALUATION_PHASES_ENUM,
	TEXT_DATA_CONTROL_STEPS_ENUM,
} from 'utils/constants';
import { formatBimestersForColumn } from 'utils/formatBimesters';
import { formatDate } from 'utils/formatDate';
import { ResponseErrors } from 'utils/parseErrors';
import * as yup from 'yup';

export interface IDateControlExportModalForm {
	periodo: IOption;
	etapa?: IOption;
}
export interface IDateControlExportModalFilters {
	periodo: string;
	etapa?: string;
}

interface IDateControlExportModalProps {
	onClose: () => void;
	isOpen: boolean;
}

const DataControlExportModal = ({ isOpen, onClose }: IDateControlExportModalProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			maxW: '1692px',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: 'gray.80',
			justifyContent: { base: 'center', md: 'flex-start' },
			padding: '2rem',
			flex: '1',
			top: '12',
		},
		content: {
			width: '100%',
			alignSelf: 'center',
			display: 'flex',
		},
		fields: {
			width: '100%',
			gap: {
				base: '1rem',
				md: '2rem',
				lg: '3rem',
			},
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'row',
			},
		},
		period: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '15rem',
			},
		},
		etapa: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '12rem',
			},
		},
		header: {
			display: 'flex',
			flexDir: 'column',
			alignItems: 'center',
			width: '100%',
		},
		header2: {
			display: 'flex',
			flexDir: 'column',
			alignItems: 'flex-start',
			width: '100%',
			mt: '2rem',
			mb: '2rem',
			p: '0',
		},
		title: { fontSize: '1.5rem', fontWeight: 700, textAlign: 'center' },
		bodyContainer: { p: '0', mb: '1rem', w: '100%' },
		body: { fontWeight: '600', textAlign: 'center', mb: '1rem', color: 'blue.700' },
		footerContainer: { justifyContent: 'center', w: '100%', p: '0' },
		footerContent: {
			w: '100%',
			gap: { base: '1.5rem', sm: '1.875rem' },
			flexDirection: { base: 'column', sm: 'row' },
			alignItems: { base: 'center', sm: 'end' },
			justifyContent: { base: 'center', md: 'end' },
		},
		buttonSubmit: {
			width: '100%',
			h: 'fit-content',
			mt: '7px',
		},
		button: {
			mt: '2rem',
			width: { base: '100%', md: '100%', lg: '15rem' },
		},
		text: {
			textAlign: 'flex-start',
			fontSize: '24px',
			color: 'gray.900',
			fontWeight: 'bold',
		},
	};

	const [filters, setFilters] = useState<IDateControlExportModalFilters>({} as IDateControlExportModalFilters);
	const { addToast } = useCustomToast();

	const schema = yup.object().shape({
		periodo: yup
			.object()
			.shape({
				label: yup.string().required('Este campo é obrigatório.'),
				value: yup.string().required('Este campo é obrigatório.'),
			})
			.required('Este campo é obrigatório.')
			.default(undefined)
			.nullable(),
	});

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IDateControlExportModalForm>({ resolver: yupResolver(schema) });

	const { data: periods, isLoading: isLoadingPeriods } = useQuery<string[], AxiosError<ResponseErrors>>(
		['periods', isOpen],
		() => getPeriodDataPact(),
		{
			onError: ({ response }) => {
				addToast({
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
					type: 'error',
				});
			},
			enabled: isOpen,
		},
	);

	const { data: dateControl, isLoading: isLoadingDateControl } = useQuery<
		IResponseExportDate,
		AxiosError<ResponseErrors>
	>(['getDataControlTableExport', isOpen, filters], () => getDataControlTableExport(filters), {
		onError: ({ response }) => {
			addToast({
				title: 'Tente novamente!',
				description: response?.data?.message || API_DEFAULT_ERROR,
				type: 'error',
			});
		},
		enabled: isOpen && !!filters.periodo,
	});

	const dataRow: IRow[] = useMemo(() => {
		return (
			dateControl?.controleDatasExportacaoDTO?.map((item: IDateControlExport) => {
				let row = new Map();
				item.prazos.sort((a: { ano: string; bimestre: string }, b: { ano: string; bimestre: string }) => {
					if (
						a.ano + BIMESTER_ENUM_TO_VALUE[a?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE] >
						b.ano + BIMESTER_ENUM_TO_VALUE[b?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE]
					)
						return 1;
					if (
						a.ano + BIMESTER_ENUM_TO_VALUE[a?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE] <
						b.ano + BIMESTER_ENUM_TO_VALUE[b?.bimestre as keyof typeof BIMESTER_ENUM_TO_VALUE]
					)
						return -1;
					return 0;
				});

				item.prazos.forEach((e: any) => {
					row.set(e.bimestre + e.ano, e.dataPrazo);
				});

				let prazosObj: { [k: string]: any } = {};

				row.forEach((value: string, key: string) => {
					prazosObj[key] = formatDate(value) || '-';
				});

				const fase =
					item.faseEnum === 'OUTRAS'
						? `${DTA_CONTROL_ENUM[item.faseEnum as keyof typeof DTA_CONTROL_ENUM]} - ${item.nomeFaseAdicional}`
						: RESULT_EVALUATION_PHASES_ENUM[item?.etapa as keyof typeof RESULT_EVALUATION_PHASES_ENUM][item?.faseEnum!];
				return {
					faseEnum: fase,

					etapa: TEXT_DATA_CONTROL_STEPS_ENUM[String(item.etapa) as keyof typeof TEXT_DATA_CONTROL_STEPS_ENUM],
					penalizado: item.penalizado,
					...prazosObj,
				};
			}) || []
		);
	}, [dateControl]);

	const parsedPeriods = useMemo(() => {
		return periods?.map(item => ({ label: item, value: item }));
	}, [periods]);

	const columns: IColumn[] = [
		{
			key: 'faseEnum',
			label: 'Fase',
		},
		{
			key: 'etapa',
			label: 'Etapa',
		},
		{
			key: 'penalizado',
			label: 'Penalizado',
		},
		...formatBimestersForColumn(dateControl?.bimestreInicial, dateControl?.anoInicial),
	];

	const hasData = useMemo(() => {
		return !!filters.periodo && !!dateControl && !isLoadingDateControl;
	}, [dateControl, filters.periodo, isLoadingDateControl]);

	const onSubmit = (values: IDateControlExportModalForm) => {
		setFilters({
			periodo: String(values?.periodo?.value),
			etapa: String(values?.etapa?.value || ''),
		});
	};

	const handleCloseModal = () => {
		setFilters({} as IDateControlExportModalFilters);
		reset();
		onClose();
	};

	const handleExport = async () => {
		await getDataControlTableExportPdf(filters).then((res: any) => {
			let binaryData = [];
			binaryData.push(res.data);
			const url = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));

			const link = document.createElement('a');

			link.href = url;

			link.setAttribute('download', 'ControleDatas.pdf');

			document.body.appendChild(link);
			link.click();
		});
	};

	return (
		<>
			<Modal isCentered isOpen={isOpen} onClose={handleCloseModal}>
				<ModalOverlay />
				<ModalContent sx={styles.container} data-testid="modal-export">
					<>
						<Box sx={styles.content} as="form" onSubmit={handleSubmit(onSubmit)}>
							<Flex sx={styles.fields}>
								<Box sx={styles.period}>
									<Controller
										name="periodo"
										control={control}
										render={({ field }) => (
											<Select
												label="Período do pacto"
												dataTestId="input--pact-period"
												placeholder="Selecione um período"
												value={field?.value}
												onChange={values => {
													if (!values) setFilters({} as IDateControlExportModalFilters);
													field?.onChange(values);
												}}
												options={parsedPeriods}
												errorMessage={errors?.periodo?.message}
												isLoading={isLoadingPeriods}
												required
											/>
										)}
									/>
								</Box>

								<Box sx={styles.etapa}>
									<Controller
										name="etapa"
										control={control}
										render={({ field }) => (
											<Select
												label="Etapa"
												dataTestId="input--step"
												placeholder="Selecione a etapa"
												value={field?.value}
												onChange={e => {
													field?.onChange(e);
												}}
												options={DATA_CONTROL_STEPS_OPTIONS}
												errorMessage={errors?.etapa?.value?.message}
											/>
										)}
									/>
								</Box>
								<Box sx={styles.buttonSubmit}>
									<Button type="submit" sx={styles.button} data-testid="button--search">
										Pesquisar
									</Button>
								</Box>
							</Flex>
						</Box>

						<ModalHeader sx={!hasData ? styles.header : styles.header2}>
							{!hasData ? <DataExportModalImg /> : <Text sx={styles.text}>{dateControl?.titulo}</Text>}
						</ModalHeader>
						<ModalCloseButton size="lg" />

						<ModalBody sx={styles.bodyContainer} data-testid="modal-export-body">
							{!hasData ? (
								<Text sx={styles.body}>
									O relatório será gerado assim que selecionar um 'Período do pacto' para ser exportado
								</Text>
							) : (
								<TableExportModal columns={columns} data={dataRow} />
							)}
						</ModalBody>

						<ModalFooter sx={styles.footerContainer}>
							<Flex sx={styles.footerContent}>
								<Button
									variant="primary"
									onClick={handleExport}
									sx={styles.button}
									data-testid="button--export"
									disabled={!hasData}
									isLoading={false}
								>
									Exportar
								</Button>
								<Button variant="secondary" onClick={handleCloseModal} sx={styles.button} data-testid="button--close">
									Fechar
								</Button>
							</Flex>
						</ModalFooter>
					</>
				</ModalContent>
			</Modal>
		</>
	);
};

export default DataControlExportModal;
