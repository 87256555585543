/**
 *
 * ReportMonitoringTable
 *
 */

import React from 'react';
import {
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableContainer,
	Box,
	SystemStyleObject,
	Text,
	Flex,
	Tooltip,
	Tag,
} from '@chakra-ui/react';
import { ReportSector } from 'types/reportMonitoring';
import { BIMESTERS_ENUM_NUMBERS, POLARITIES, STEPS_OPTIONS_FILTER, UNIDADEMEDIDA } from 'utils/constants';
import { formatDecimalNumber, formatAndMaskCell } from 'utils/formatPerformanceNumber';
import { verifyCell } from 'utils/verifyCell';

interface ReportMonitoringTableProps {
	setor: ReportSector;
}

const ReportMonitoringTable = ({ setor }: ReportMonitoringTableProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
		},
		tableContainer: {
			fontFamily: 'Lato',
			maxWidth: '74.125rem',
			overflowX: 'scroll',

			'::-webkit-scrollbar': {
				width: '1rem',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.5rem',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#68B2A0',
				borderRadius: '0.5rem',
			},
		},
		headerTitleContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			paddingX: '1.5rem',
			paddingTop: '1.25rem',
			paddingBottom: '1.125rem',
			backgroundColor: '#205072',
			borderTopRadius: '0.5rem',
			width: 'inhet',
		},
		headerTitle: {
			fontSize: '1.25rem',
			color: '#fff',
		},
		textPrimaryColor: {
			color: '#fff',
			textTransform: 'none',
			fontSize: '1rem',
		},
		tableFooter: {
			backgroundColor: '#68B2A080',
			paddingX: '1rem',
			paddingY: '0.5rem',
			marginTop: '0.25rem',
			marginBottom: '1rem',
			fontSize: '1rem',
		},
		tableBodyrows: {
			paddingY: '1.19rem',
			fontWeight: '500',
			textWrap: 'wrap',
			fontSize: '1rem',
			backgroundColor: '#fff',
		},
		lastTableBodyRow: {
			paddingY: '1.19rem',
			fontWeight: '500',
			textWrap: 'wrap',
			borderRight: '2px solid #d3d3d3',
			fontSize: '1rem',
		},
		firstTableheaderRow: {
			color: '#fff',
			textTransform: 'none',
			fontWeight: '700',
			paddingRight: '18rem',
			paddingLeft: '1.5rem',
			fontSize: '1rem',
		},
		tableHeaderRows: {
			color: '#fff',
			textTransform: 'none',
			fontWeight: '700',
			paddingRight: '4.5rem',
			fontSize: '1rem',
		},
		lastTableHeaderRow: {
			color: '#fff',
			textTransform: 'none',
			fontWeight: '700',
			paddingRight: '2rem',
			borderRight: '2px solid #d3d3d3',
			fontSize: '1rem',
		},
		percentageRows: {
			color: '#2A89D8',
			paddingY: '1.19rem',
			fontWeight: '700',
			fontSize: '1rem',
			backgroundColor: '#fff',
		},
		tableFooterText: {
			color: '#000000de',
			fontWeight: '700',
			paddingY: '0.5rem',
			fontSize: '1rem',
		},
		tableFooterCalcContainer: {
			gap: '0.5rem',
			alignItems: 'center',
		},
		tableFooterCalc: {
			color: '#C30',
			fontWeight: '700',
			paddingY: '0.5rem',
			fontSize: '1rem',
		},
		tableHeadRow: {
			backgroundColor: '#205072',
		},
		tableBimesterHeader: {
			borderBottom: 'none',
		},
		tableBimesterHeaderText: {
			fontSize: '1rem',
			color: 'white',
			paddingLeft: '13rem',
			borderBottom: 'none',
		},
		nameContainer: {
			maxWidth: '74.125rem',
		},
		tooltip: {
			color: 'black',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			top: '15px',
		},
		indicatorText: {
			fontWeight: '500',
			fontSize: '1rem',
			maxW: '350px',
			w: '100%',
		},
		tag: {
			minW: '3.625rem',
			h: '1.25rem',
			fontSize: '1rem',
			borderRadius: '0.75rem',
			backgroundColor: '#3498DB',
			marginRight: '0.5rem',
		},
	};

	const commonColumns = ['Meta', 'Resultado', 'Desempenho', 'Peso', 'Pontuação'];

	const isLastItem = (i: number, length: number) => i === length - 1;
	const isFirstItem = (i: number) => i === 0;

	const bimestres = setor.indicadores[0].metricas.flat();

	return (
		<Box sx={styles.container}>
			<Box key={setor.nomeSetor} sx={styles.nameContainer}>
				<Box sx={styles.headerTitleContainer}>
					<Tag variant="solid" sx={styles.tag} data-testid="text--etapa">
						{STEPS_OPTIONS_FILTER[setor?.etapa as keyof typeof STEPS_OPTIONS_FILTER]}
					</Tag>
					<Text sx={styles.headerTitle}>{setor.nomeSetor}</Text>
				</Box>
				<TableContainer sx={styles.tableContainer}>
					<Table variant={'simple'} backgroundColor="#fff">
						<Thead>
							<Tr sx={styles.tableHeadRow}>
								<Th colSpan={2} sx={styles.tableBimesterHeader} />
								{bimestres.map((bimestre, bimestreIdx) => (
									<Th
										colSpan={5}
										sx={styles.tableBimesterHeaderText}
										key={bimestre.id}
										borderRight={isLastItem(bimestreIdx, bimestres.length) ? 'none' : '2px solid #d3d3d3'}
									>
										{BIMESTERS_ENUM_NUMBERS[bimestre.bimestre as keyof typeof BIMESTERS_ENUM_NUMBERS]} bimestre{' '}
										{bimestre.ano}
									</Th>
								))}
							</Tr>
						</Thead>
						<Thead>
							<Tr sx={styles.tableHeadRow}>
								<Th sx={styles.firstTableheaderRow}>Nome do indicador</Th>
								<Th sx={styles.tableHeaderRows}>Polaridade</Th>
								{bimestres.map((_, bimestreIdx) => (
									<React.Fragment key={bimestreIdx}>
										{commonColumns.map((columns, columnIdx) => (
											<Th
												sx={
													isLastItem(columnIdx, commonColumns.length) && !isLastItem(bimestreIdx, bimestres.length)
														? styles.lastTableHeaderRow
														: styles.tableHeaderRows
												}
												paddingLeft={!isFirstItem(bimestreIdx) && isFirstItem(columnIdx) ? '1.5rem' : '0px'}
												key={columns}
											>
												{columns}
											</Th>
										))}
									</React.Fragment>
								))}
							</Tr>
						</Thead>
						<Tbody>
							{setor.indicadores.map(indicador => (
								<React.Fragment key={indicador.id}>
									<Tr>
										<Td sx={styles.tableBodyrows} paddingLeft={'1.5rem'}>
											<Text sx={styles.indicatorText}>{indicador.nome}</Text>
										</Td>
										<Td sx={styles.tableBodyrows}>{POLARITIES[indicador.polaridade as keyof typeof POLARITIES]}</Td>
										{indicador.metricas?.map((bimestre, i) => (
											<React.Fragment key={bimestre.id}>
												<Td
													sx={bimestre.resultado ? styles.percentageRows : styles.tableBodyrows}
													paddingLeft={!isFirstItem(i) ? '1.5rem' : '0'}
												>
													{formatAndMaskCell(bimestre.meta, indicador?.unidadeMedida)}
												</Td>
												<Td sx={bimestre.resultado ? styles.percentageRows : styles.tableBodyrows}>
													{formatAndMaskCell(bimestre.resultado, indicador?.unidadeMedida)}
												</Td>
												<Td sx={bimestre.desempenho ? styles.percentageRows : styles.tableBodyrows}>
													{verifyCell(bimestre.desempenho, UNIDADEMEDIDA?.PERCENTUAL)}
												</Td>
												<Td sx={styles.tableBodyrows}>
													{bimestre.pesoCalculado * 100 <= 0 ? 'N/A' : verifyCell(bimestre.pesoCalculado * 100)}
												</Td>
												<Td
													sx={styles.tableBodyrows}
													borderRight={!isLastItem(i, indicador.metricas.length) ? ' 2px solid #d3d3d3' : 'none'}
												>
													{verifyCell(bimestre.pontuacao)}
												</Td>
											</React.Fragment>
										))}
									</Tr>
									<Tr height={'0.25rem'}>
										{indicador.metricas.map((item, index) => (
											<Td
												key={item.id}
												colSpan={isFirstItem(index) ? 7 : 5}
												borderRight={!isLastItem(index, indicador.metricas.length) ? ' 2px solid #d3d3d3' : 'none'}
											/>
										))}
									</Tr>
								</React.Fragment>
							))}
						</Tbody>
						<Tfoot sx={styles.tableFooter}>
							<Tr>
								<Th sx={styles.tableFooterText} colSpan={2} paddingLeft={'1.5rem'}>
									TOTAL GERAL
								</Th>
								{setor.totalizacao.map((bimestre, bimestreIdx) => (
									<React.Fragment key={bimestreIdx}>
										<Th
											sx={styles.tableFooterText}
											colSpan={4}
											paddingLeft={!isFirstItem(bimestreIdx) ? '29.5rem' : '28rem'}
										></Th>
										<Th borderRight={isLastItem(bimestreIdx, bimestres.length) ? 'none' : '2px solid #d3d3d3'}>
											<Flex sx={styles.tableFooterCalcContainer}>
												<Tooltip
													placement="top-start"
													hasArrow
													arrowSize={7}
													sx={styles.tooltip}
													bg="white"
													label="Resultado total com pontos subtraídos"
												>
													<Text sx={styles.tableFooterText}>{formatDecimalNumber(bimestre.desempenho, '*')}</Text>
												</Tooltip>
												<Text sx={styles.tableFooterCalc}>({bimestre?.notaPenalizacao}*)</Text>
											</Flex>
										</Th>
									</React.Fragment>
								))}
							</Tr>
						</Tfoot>
					</Table>
					<Box height={'0.5rem'} />
				</TableContainer>
			</Box>
		</Box>
	);
};

export default ReportMonitoringTable;
