import { Icon } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { ClearIndicatorProps, ValueContainerProps, components } from 'react-select';
import { IOption } from 'types/select';

export const ValueContainer = ({ children, ...props }: ValueContainerProps) => {
	const { getValue, options } = props;
	const length = getValue().length;
	const optionsWithoutAll = (options as IOption[]).filter(item => item.value !== 'Todos');

	const isMenuOpen = props?.selectProps?.menuIsOpen;

	return (
		<components.ValueContainer {...props}>
			<div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '27px' }}>
				<p style={{ display: 'flex', opacity: length > 1 ? 1 : 0, width: length > 1 ? 'fit-content' : '0' }}>
					{length === optionsWithoutAll?.length
						? 'Todos'
						: isMenuOpen
						? `${props?.selectProps?.inputValue}`
						: `${length} items selecionados`}
				</p>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						opacity: length > 1 ? 0 : 1,
						pointerEvents: 'none',
						width: length > 1 ? '0' : 'fit-content',
						textOverflow: 'ellipsis',
						overflow: 'auto',
					}}
				>
					{children}
				</div>
			</div>
		</components.ValueContainer>
	);
};

export const ClearIndicator = (props: ClearIndicatorProps) => {
	const onClear = () => {
		const options = props?.options as IOption[];
		const values = options?.filter(e => e?.isDisabled);
		props?.clearValue();
		props?.setValue?.(values, 'deselect-option');
	};

	return <Icon as={MdClose} size={25} onClick={onClear} mt="2.5px" />;
};
