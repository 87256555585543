/**
 *
 * Header
 *
 */

import NotificationsMenu from '../NotificationsMenu';
import { useMemo } from 'react';
import {
	Avatar,
	Box,
	Flex,
	Link,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	SystemStyleObject,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { OptionsIcon } from 'assets/icons';
import { useIsMobile } from 'hooks/useIsMobile';
import { useSession } from 'hooks/useSession';
import { USER_ROLES } from 'utils/constants';

const Header = () => {
	const isMobile = useIsMobile(992);
	const styles: Record<string, SystemStyleObject> = {
		container: {
			background: 'white',
			w: '100%',
			h: isMobile ? '4rem' : '5.9375rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		content: {
			w: '100%',
			maxW: '112.625rem',
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			gap: '1.5rem',
		},
		notification: {
			cursor: 'pointer',
			color: 'black',
			p: '1.5rem 0',
			gap: '1.5rem',
		},
		profileContainer: {
			position: 'relative',
		},
		profileContent: {
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		profileImage: {
			w: '2.75rem',
			h: '2.75rem',
			borderRadius: 'full',
			backgroundColor: 'blue.700',
			color: 'white',
		},
		profileName: {
			fontSize: '1rem',
			fontWeight: '600',
			display: {
				base: 'none',
				sm: 'inline-block',
			},
			marginRight: '1.5rem',
		},
		actions: {
			gap: {
				base: '0',
				sm: '2rem',
				md: '3.3125rem',
			},
			backgroundColor: 'transparent',
		},
		linkHeader: {
			display: 'flex',
			gap: '1rem',
			alignItems: 'center',
		},
		links: {
			width: { base: '100%' },
			zIndex: '99',
		},
		linkName: {
			fontSize: '1rem',
			fontWeight: '600',
			display: {
				sm: 'inline-block',
			},
		},
		linkBody: {
			display: 'flex',
			flexDirection: 'column',
			gap: '1rem',
		},
		tooltip: {
			bg: 'white',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			color: '#3B3333',
		},
		text: {
			fontSize: '12px',
			color: 'gray.650',
		},
		accessLevels: {
			gap: '6px',
		},
	};

	const { session } = useSession();
	const MINHA_CONTA_SAU = 'https://sau-inpacto.fhemig.mg.gov.br/sau/login/alterarSenha';

	const profile = useMemo(() => {
		const isPresident = session?.user?.permissoes.find(permissions => permissions.nome === USER_ROLES.PRESIDENTE);
		const isTecnicalAreaAgreed = session?.user?.permissoes.find(permissions =>
			[USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO].includes(permissions.nome),
		);
		const isServidor = session?.user?.permissoes.find(permissions => permissions.nome === USER_ROLES.SERVIDOR);

		const isPresentative = session?.user?.isRepresentante;

		if (isPresident) return 'Presidente';

		if (isTecnicalAreaAgreed) return 'Assessor Estratégico';

		if (isServidor) {
			let profile = 'Servidor';

			if (isPresentative) profile = 'Representante';

			return profile;
		}
	}, [session]);

	return (
		<Box sx={styles.container}>
			<Box sx={styles.content}>
				<Box sx={styles.profileContainer}>
					<Popover placement="bottom-end">
						<Box sx={styles.profileContent}>
							<Text sx={styles.profileName}>{session?.user?.nomeCompleto}</Text>
							<Avatar sx={styles.profileImage} name={session?.user?.nomeCompleto}></Avatar>

							<PopoverTrigger>
								<Box>
									<Tooltip label="Opções da conta" placement="left" sx={styles.tooltip}>
										<span>
											<OptionsIcon />
										</span>
									</Tooltip>
								</Box>
							</PopoverTrigger>
						</Box>
						<Flex sx={styles?.accessLevels}>
							<Text sx={styles.text}>{profile}</Text>
						</Flex>
						<PopoverContent sx={styles.links}>
							<PopoverArrow />

							<PopoverBody sx={styles.linkBody}>
								<Link href={MINHA_CONTA_SAU} isExternal>
									Minha conta - SAU
								</Link>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</Box>
				<Flex sx={styles.actions}>
					<NotificationsMenu />
				</Flex>
			</Box>
		</Box>
	);
};

export default Header;
