/**
 *
 * DataControlFilter
 *
 */

import CustomCalendar from '../CustomCalendar';
import { IUnit } from '../DataControlForm';
import Select from '../Select';
import { useMemo, useState } from 'react';
import { SystemStyleObject, Box, Flex, Button, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { getSector } from 'services/http/sectors';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import { API_DEFAULT_ERROR, RESULT_EVALUATION_PHASES, SECTOR_TYPE } from 'utils/constants';
import {
	BIMESTERS_OPTIONS,
	BIMESTER_TOOLTIP_INFO,
	DATA_CONTROL_STEPS_OPTIONS,
	DATA_PHASES,
	DATA_PHASES_ETAPA_UM,
} from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import * as yup from 'yup';

export interface IDataControlFilterForm {
	nomeFase?: IOption | null;
	ano?: string;
	bimestre?: IOption;
	etapa?: IOption;
	unidade?: IOption;
	idUnidade?: string;
}

export type paramsKeys = keyof IDataControlFilterForm;

interface DataControlFilterProps {
	defaultValues?: IDataControlFilterForm;
	isListing?: boolean;
	onSubmitForm?: (values: IDataControlFilterForm) => void;
}

const DataControlFilter = ({ isListing = true, defaultValues, onSubmitForm }: DataControlFilterProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			height: 'auto',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: 'gray.80',
			justifyContent: { base: 'center', md: 'flex-start' },
			padding: '2rem',
		},
		content: {
			width: '100%',
		},
		fields: {
			display: 'flex',
			width: '100%',
			alignItems: 'baseline',
			gap: {
				base: '2rem',
				md: '2rem',
				lg: '3rem',
			},
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'row',
			},
		},
		nomeFase: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '20rem',
			},
		},
		unidadeContent: {
			maxW: {
				base: '100%',
				'2xl': '20rem',
			},
			w: '100%',
		},
		ano: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '12rem',
			},
		},
		bimestre: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '14rem',
			},
		},
		etapa: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '100%',
				lg: '12rem',
			},
		},
		buttonSubmit: {
			width: '100%',
		},
		button: {
			mt: '2rem',
			width: { base: '100%', md: '100%', lg: '15rem' },
		},
		errorText: {
			fontSize: '0.9rem',
			color: 'red.400',
			my: '0.5rem',
		},
	};

	const schema = yup.object().shape({
		nomeFase: yup.object().shape({
			label: yup.string().required('Este campo é obrigatório.'),
			value: yup.string().required('Este campo é obrigatório.'),
		}),
		ano: yup
			.mixed()
			.required('Este campo é obrigatório.')
			.test('noValue', 'Este campo é obrigatório.', (value = '') => {
				const maxValue = '';
				const isInvalid = maxValue === value;

				return !isInvalid;
			}),
		bimestre: yup.object().shape({
			label: yup.string().required('Este campo é obrigatório.'),
			value: yup.string().required('Este campo é obrigatório.'),
		}),
		etapa: yup.object().shape({
			label: yup.string().required('Este campo é obrigatório.'),
			value: yup.string().required('Este campo é obrigatório.'),
		}),
	});

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		resetField,
		formState: { errors },
	} = useForm<IDataControlFilterForm>({
		resolver: isListing ? undefined : yupResolver(schema),
		defaultValues,
	});

	const currentYear = new Date().getFullYear();
	const yearMinDate = currentYear - 5;
	const yearMaxDate = currentYear + 2;

	const etapa = watch('etapa');
	const fase = watch('nomeFase');
	const hasError = watch('ano');

	const DATA_PHASES_OPTIONS = etapa ? DATA_PHASES[etapa.value as keyof typeof DATA_PHASES] : DATA_PHASES_ETAPA_UM;

	const handleChangeStep = (newValue: IOption) => {
		if (fase?.value === RESULT_EVALUATION_PHASES.DECISAO_CAAP) {
			const value = DATA_PHASES[newValue.value as keyof typeof DATA_PHASES].find(
				item => item.value === RESULT_EVALUATION_PHASES.DECISAO_CAAP,
			);

			resetField('nomeFase', { defaultValue: value });
		}
	};

	const { addToast } = useCustomToast();
	const [isInvalid, setIsInvalid] = useState(false);

	const {
		session: { user },
	} = useSession();
	const userUnits = stringifyCurrentUserUnits(user);

	const secondStep = [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION];

	const { data: agreeds, isLoading: isAgreedsLoading } = useQuery<ISector[], AxiosError<ResponseErrors>>(
		['agreeds'],
		() => getSector(secondStep, userUnits),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
		},
	);

	const unidades = useMemo(() => {
		const map = new Map();
		const _unidades: IUnit[] = [];

		agreeds?.forEach(item => {
			if (!map.has(item.idUnidade)) map.set(item.idUnidade, item.sigla);
		});

		map.forEach((value, key) => _unidades.push({ id: key, unidade: value }));

		const unitParsed = parsedOptionArray(_unidades, 'unidade', 'id');
		const currentUnit = unitParsed?.find(unit => String(unit?.value) === defaultValues?.idUnidade);

		if (currentUnit) setValue('unidade', currentUnit);

		return _unidades;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [agreeds]);

	const onSubmit = (values: IDataControlFilterForm) => {
		const isInvalid = isEmpty(cleanDeep(values));

		setIsInvalid(isInvalid);

		if (!isInvalid) {
			onSubmitForm?.(values);
		}
	};

	return (
		<Flex sx={styles?.container}>
			<Box sx={styles?.content} as="form" onSubmit={handleSubmit(onSubmit)}>
				<Box sx={styles.fields}>
					<Box sx={styles.unidadeContent}>
						<Controller
							name="unidade"
							control={control}
							render={({ field }) => (
								<Select
									label="Unidade"
									value={field?.value}
									onChange={field?.onChange}
									options={parsedOptionArray(unidades, 'unidade', 'id')}
									placeholder={'Selecione a unidade'}
									errorMessage={errors?.unidade?.message}
									dataTestId="select--unidade"
									isLoading={isAgreedsLoading}
								/>
							)}
						/>
					</Box>
					<Box sx={styles?.nomeFase}>
						<Controller
							name="nomeFase"
							control={control}
							render={({ field }) => (
								<>
									<Select
										label="Nome da fase"
										dataTestId="input--phaseName"
										placeholder="Selecione a fase"
										value={field?.value}
										onChange={field?.onChange}
										options={DATA_PHASES_OPTIONS}
										errorMessage={errors?.nomeFase?.value?.message}
									/>
								</>
							)}
						/>
					</Box>
					<Box sx={styles?.ano}>
						<CustomCalendar
							label="Ano"
							placeholder="Selecione o ano"
							fieldName="ano"
							dataTestId="input--year"
							errorMessage={!hasError ? errors.ano?.message : ''}
							setValue={setValue}
							minDate={new Date(yearMinDate, 0, 1)}
							maxDate={new Date(yearMaxDate, 0, 1)}
							control={control}
						/>
					</Box>
					<Box sx={styles?.bimestre}>
						<Controller
							name="bimestre"
							control={control}
							render={({ field }) => (
								<>
									<Select
										tooltipInfo={BIMESTER_TOOLTIP_INFO}
										label="Bimestre"
										dataTestId="input--bimester"
										placeholder="Selecione o bimestre"
										value={field?.value}
										onChange={field?.onChange}
										options={BIMESTERS_OPTIONS}
										errorMessage={errors?.bimestre?.value?.message}
									/>
								</>
							)}
						/>
					</Box>

					<Box sx={styles?.etapa}>
						<Controller
							name="etapa"
							control={control}
							render={({ field }) => (
								<>
									<Select
										label="Etapa"
										dataTestId="input--step"
										placeholder="Selecione a etapa"
										value={field?.value}
										onChange={e => {
											field?.onChange(e);

											handleChangeStep(e as unknown as IOption);
										}}
										options={DATA_CONTROL_STEPS_OPTIONS}
										errorMessage={errors?.etapa?.value?.message}
									/>
								</>
							)}
						/>
					</Box>
				</Box>
				{isInvalid && (
					<Text sx={styles.errorText} data-testid="text--error">
						É necessário escolher um filtro para continuar.
					</Text>
				)}
				<Box sx={styles.buttonSubmit}>
					<Button type="submit" sx={styles.button} data-testid="button--submit">
						Pesquisar
					</Button>
				</Box>
			</Box>
		</Flex>
	);
};

export default DataControlFilter;
