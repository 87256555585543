/**
 *
 * AccordionDataControl
 *
 */

import CustomModal from '../CustomModal';
import { useMemo } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	Flex,
	IconButton,
	Radio,
	RadioGroup,
	Stack,
	SystemStyleObject,
	Tag,
	TagLabel,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { DeleteIcon, TableEditIcon } from 'assets/icons';
import { differenceInDays } from 'date-fns';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { DataControlData } from 'types/dateControl';
import {
	BIMESTERS_ENUM,
	DATA_CONTROL_STATUS,
	USER_ROLES,
	TEXT_TO_DATA_CONTROL_PHASES_ENUM,
	STEPS_ENUM,
	DATA_CONTROL_PENALIZED_ENUM,
	RESULTER_FILTER_STEPS,
	RESULT_EVALUATION_PHASES_ENUM,
} from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { formatDateLate } from 'utils/formatEnum';

interface AccordionDataControlProps {
	data: DataControlData;
	editDataControl: (id: string) => void;
	deleteDataControl: (id: string) => void;
}

const AccordionDataControl = ({ data, deleteDataControl, editDataControl }: AccordionDataControlProps) => {
	const daysLate = differenceInDays(new Date(String(data.prazo.dataPrazo)), new Date());

	const overdueOneDay = formatDateLate(daysLate);

	const firstStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const secondStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);

	const hasPermission = data?.etapa === STEPS_ENUM.ETAPA_UM ? firstStepPermission : secondStepPermission;

	const getDataControlStatus = (days: number) => {
		if (days > 0) return 'NO_PRAZO';
		if (days === 0) return 'DIA_LIMITE';
		if (days < 0) return 'ATRASADO';
	};

	const status = getDataControlStatus(daysLate);

	const styles: Record<string, SystemStyleObject> = {
		accordionItem: {
			borderWidth: '1px',
			borderColor: 'gray.95',
			mb: '0.1rem',
			borderRadius: '0.25rem',
		},
		accordionButtonContainer: {
			alignContent: 'center',
			minH: {
				base: 'auto',
				md: '4.25rem',
			},
			wordBreak: 'break-word',
		},
		accordionButtonContainerExpanded: {
			alignContent: 'center',
			minH: {
				base: 'auto',
				md: '5rem',
			},
			wordBreak: 'break-word',
		},
		accordionButton: {
			_hover: {},
			height: 'auto',
			paddingTop: '0.625rem',
		},
		accordionIcon: {
			h: '2.25rem',
			w: '2.25rem',
		},
		titleContainer: {
			flex: '1',
			textAlign: 'left',
			ml: { base: '0', lg: '2rem' },
			alignItems: {
				base: 'flex-start',
				lg: 'center',
			},
			gap: '0.4rem',
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		titleDirection: {
			flexDir: {
				base: 'column',
				lg: 'row',
			},
			alignItems: {
				base: 'start',
				lg: 'center',
			},
		},
		title: {
			fontSize: {
				base: '1rem',
				sm: '1.125rem',
			},
			textAlign: {
				base: 'start',
				sm: 'center',
			},
			fontWeight: 'medium',
		},
		titleExpanded: {
			fontSize: '1.25rem',
		},
		period: {
			fontSize: {
				base: '1rem',
				sm: '1.125rem',
			},
			fontWeight: 'semibold',
			textAlign: 'center',
			whiteSpace: 'nowrap',
		},
		periodExpanded: {
			fontSize: '1.25rem',
		},
		icons: {
			gap: '1.5rem',
			paddingRight: {
				base: '0',
				md: '2rem',
				lg: '6rem',
			},
			alignSelf: 'center',
		},
		tagLabel: {
			display: 'flex',
			alignItems: 'center',
			gap: '0.313rem',
			color: 'black',
			fontSize: '1rem',
			fontWeight: 'medium',
		},
		tagIconContainer: {
			mr: '0.375rem',
		},
		divider: {
			p: '0rem 5rem 0rem 2rem',
		},
		accordionPanel: {
			ps: '2rem',
			pe: '5rem',
		},
		dot: {
			color: DATA_CONTROL_STATUS[status!].color,
		},
		accordionPanelContainer: {
			flexDir: 'column',
			h: '100%',
			ml: {
				base: '0rem',
				lg: '3.1rem',
			},
		},
		panelFirstSectionContainer: {
			alignItems: 'center',
			gap: '0.5rem',
			mt: '1rem',
			mr: {
				base: '0rem',
				lg: '3.1rem',
			},
		},
		panelFirstSectionText: {
			fontSize: '1rem',
			fontWeight: 'semibold',
		},
		panelFirstSectionCard: {
			color: 'black',
			fontSize: '1rem',
			fontWeight: 'semibold',
			backgroundColor: '#E3E3E3',
			borderRadius: '0.375rem',
			p: '0.438rem 1rem',
		},
		panelSecondSectionContainer: {
			flexDir: 'column',
			mr: {
				base: '0rem',
				lg: '3.1rem',
			},
		},
		panelSecondSectionContent: {
			mt: '1.75rem',
			gap: '1.563rem',
			_last: {
				mb: '2.5rem',
			},
			justifyContent: 'space-between',
			alignItems: {
				base: 'flex-start',
				xl: 'flex-end',
			},
			flexDir: {
				base: 'column',
				xl: 'row',
			},
		},
		panelSecondSectionLeftContent: {
			gap: '0.375rem',
			alignItems: 'center',
		},
		panelSecondSectionDateContainer: {
			minW: '9.5rem',
			h: '2.75rem',
			border: '1px solid',
			borderBottom: '5px solid',
			borderColor: DATA_CONTROL_STATUS[status!].color,
			borderRadius: '6px',
			fontSize: '1.25rem',
			fontWeight: 'semibold',
			textAlign: 'center',
			color: 'black',
			px: '0.625rem',
			alignItems: 'center',
			justifyContent: 'center',
		},
		panelSecondSectionTagContainer: {
			w: '100%',
		},
		panelSecondSectionRightContent: {
			gap: '2.25rem',
			w: {
				base: '100%',
				lg: 'fit-content',
			},
			alignItems: {
				base: 'flex-start',
				lg: 'flex-end',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		panelSecondSectionRadioGroupContainer: {
			mb: '0.5rem',
			gap: '1.5rem',
		},
		panelSecondSectionText: {
			fontSize: '1.25rem',
			fontWeight: 'semibold',
		},
		panelSecondSectionInputsContainer: {
			gap: '2rem',
			w: '100%',
			flex: '1',
		},
		panelSecondSectionLabel: {
			fontSize: '1rem',
			fontWeight: 'medium',
			mb: '1rem',
		},
		panelSecondSectionTextContent: {
			backgroundColor: '#F2F2F2',
			border: '1px solid',
			borderColor: '#E1E2E5',
			borderRadius: '4px',
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#000000',
			px: '1rem',
			h: '2.75rem',
			alignItems: 'center',
			minW: {
				base: '100%',
				lg: '9.563rem',
			},
			w: '100%',
		},
		panelSecondSectionBottomContent: {
			mt: '2rem',
			mb: '1rem',
			flexDir: 'column',
			gap: '1rem',
		},
		panelThirdSectionContainer: {
			mt: '1rem',
			flexDir: 'column',
			mr: {
				base: '0rem',
				lg: '3.1rem',
			},
		},
		panelThirdSectionContent: {
			flexDir: 'column',
			mt: '0.875rem',
		},
		panelContentTitle: {
			fontSize: '1rem',
			fontWeight: 'bold',
		},
		panelContentText: {
			fontSize: '1rem',
			fontWeight: 'normal',
			whiteSpace: 'pre-line',
		},
		tagContent: {
			alignItems: { base: 'center' },
			justifyContent: 'normal',
			flexDirection: { base: 'row' },
		},
		flag: {
			fontSize: '0.75rem',
			backgroundColor: '#3498DB',
			color: '#fff',
			p: '2px 10px',
			borderRadius: '20px',
			fontWeight: '500',
			mr: '.5rem',
		},
		unitFlag: {
			fontSize: '0.75rem',
			backgroundColor: '#56C596',
			color: '#fff',
			p: '2px 10px',
			borderRadius: '20px',
			fontWeight: '500',
			mr: '.5rem',
		},
	};

	const { isOpen, onOpen, onClose } = useDisclosure();

	const showPenalty = useMemo(() => {
		return data.penalidade.aplicavel;
	}, [data]);

	const handleGoBack = () => {
		onClose();
	};

	const handleDelete = (dateControlId: string) => {
		onClose();
		deleteDataControl(dateControlId);
	};

	const renderDataControlTitle = (faseEnum: string, faseAdicional: string) => {
		if (faseEnum === TEXT_TO_DATA_CONTROL_PHASES_ENUM.Outras) return faseAdicional;

		return RESULT_EVALUATION_PHASES_ENUM[data?.etapa as keyof typeof RESULT_EVALUATION_PHASES_ENUM][faseEnum];
	};

	return (
		<>
			<Accordion allowMultiple>
				<AccordionItem sx={styles.accordionItem}>
					{({ isExpanded }) => (
						<>
							<Flex sx={isExpanded ? styles.accordionButtonContainerExpanded : styles.accordionButtonContainer}>
								<AccordionButton sx={styles.accordionButton} data-testid="accordionDataControl--btn">
									<AccordionIcon sx={styles.accordionIcon} />
									<Flex sx={styles.titleContainer}>
										<Flex sx={styles.titleDirection}>
											<Text sx={styles.flag}>
												{RESULTER_FILTER_STEPS[data.etapa as keyof typeof RESULTER_FILTER_STEPS]}
											</Text>
											{!!data?.siglaUnidade && <Text sx={styles.unitFlag}>{data?.siglaUnidade}</Text>}
											<Text sx={isExpanded ? styles.titleExpanded : styles.title}>
												{renderDataControlTitle(data.faseEnum, data.faseAdcional!)} —
											</Text>
											<Text sx={isExpanded ? styles.periodExpanded : styles.period}>
												{BIMESTERS_ENUM[data.prazo.bimestre as keyof typeof BIMESTERS_ENUM]}
											</Text>
										</Flex>

										<Tag
											variant="outline"
											colorScheme="black"
											data-testid="accordionDataControl--expirationDate"
											sx={styles.tagContent}
										>
											<Flex sx={styles.tagIconContainer}>{DATA_CONTROL_STATUS[status!].icon}</Flex>
											<TagLabel sx={styles.tagLabel}>
												{DATA_CONTROL_STATUS[status!].label}
												{status === 'ATRASADO' && (
													<>
														<Text sx={styles.dot}>•</Text> {overdueOneDay > 1 ? `${overdueOneDay} dias` : '1 Dia'}
													</>
												)}
											</TagLabel>
										</Tag>
									</Flex>
								</AccordionButton>
								<Flex sx={styles.icons}>
									{hasPermission && (
										<IconButton
											aria-label={'Editar controle de datas'}
											variant="unstyled"
											icon={<TableEditIcon />}
											onClick={() => editDataControl(data.id)}
											data-testid={`accordionDataControl--edit-btn-${data.id}`}
										/>
									)}
									{hasPermission && (
										<IconButton
											aria-label={'Excluir controle de datas'}
											variant="unstyled"
											icon={<DeleteIcon />}
											onClick={onOpen}
											data-testid={`accordionDataControl--delete-btn-${data.id}`}
											visibility={status === 'ATRASADO' ? 'hidden' : 'visible'}
										/>
									)}
								</Flex>
							</Flex>

							<Box sx={styles.divider}>
								<Divider />
							</Box>

							<AccordionPanel sx={styles.accordionPanel}>
								<Flex sx={styles.accordionPanelContainer}>
									<Flex sx={styles.panelFirstSectionContainer}>
										<Text sx={styles.panelFirstSectionText}>
											Data da última atualização: {formatDate(data.dataAtualizacao)}
										</Text>
										<Text sx={styles.dot}>•</Text>
										<Flex sx={styles.panelFirstSectionCard} data-testid="accordionDataContro--responsavel">
											{data.responsavelAtualizacao}
										</Flex>
									</Flex>
									<Flex sx={styles.panelSecondSectionContainer}>
										<Flex sx={styles.panelSecondSectionContent}>
											<Flex sx={styles.panelSecondSectionLeftContent}>
												<Flex sx={styles.panelSecondSectionDateContainer}>
													<Text sx={styles.panelSecondSectionText}>{formatDate(data.prazo.dataPrazo)}</Text>
												</Flex>

												<Flex sx={styles.panelSecondSectionTagContainer}>
													<Tag variant="outline" colorScheme="black">
														<Flex sx={styles.tagIconContainer}>{DATA_CONTROL_STATUS[status!].icon}</Flex>
														<TagLabel sx={styles.tagLabel}>
															{DATA_CONTROL_STATUS[status!].label}
															{status === 'ATRASADO' && (
																<>
																	<Text sx={styles.dot}>•</Text> {overdueOneDay > 1 ? `${overdueOneDay} dias` : '1 Dia'}
																</>
															)}
														</TagLabel>
													</Tag>
												</Flex>
											</Flex>

											<Flex sx={styles.panelSecondSectionRightContent}>
												<Flex sx={styles.panelSecondSectionRadioGroupContainer}>
													<Text sx={styles.panelSecondSectionText}>Aplica penalidade</Text>
													<RadioGroup defaultValue={data.penalidade.aplicavel ? '1' : '2'} isDisabled>
														<Stack direction="row">
															<Radio size="lg" value="1">
																Sim
															</Radio>
															<Radio size="lg" value="2">
																Não
															</Radio>
														</Stack>
													</RadioGroup>
												</Flex>

												<Flex sx={styles.panelSecondSectionInputsContainer}>
													{showPenalty && (
														<>
															<Flex flexDir="column" minW="10.438rem" w={{ base: '100%', lg: 'fit-content' }}>
																<Text sx={styles.panelSecondSectionLabel}>Pontos perdidos por dia</Text>

																<Flex sx={styles.panelSecondSectionTextContent}>{data.penalidade.pontosPerdidos}</Flex>
															</Flex>
															<Flex flexDir="column" w={{ base: '100%', lg: 'fit-content' }}>
																<Text sx={styles.panelSecondSectionLabel}>Penalizado</Text>

																<Flex sx={styles.panelSecondSectionTextContent}>
																	{
																		DATA_CONTROL_PENALIZED_ENUM[
																			data.penalidade.penalizadoEnum as keyof typeof DATA_CONTROL_PENALIZED_ENUM
																		]
																	}
																</Flex>
															</Flex>
														</>
													)}
												</Flex>
											</Flex>
										</Flex>

										{data?.descricao && (
											<Flex sx={styles.panelSecondSectionBottomContent}>
												<Text sx={styles.panelContentTitle}>Descrição</Text>

												<Text sx={styles.panelContentText}>{data.descricao}</Text>
											</Flex>
										)}

										<Divider />
									</Flex>

									<Flex sx={styles.panelThirdSectionContainer}>
										<Flex sx={styles.panelThirdSectionContent}>
											<Text sx={styles.panelContentTitle}>Cláusula de justificativa</Text>
											<Text sx={styles.panelContentText}>{data.clausulaJustificativa}</Text>
										</Flex>

										<Flex sx={styles.panelThirdSectionContent}>
											<Text sx={styles.panelContentTitle}>Justificativa</Text>
											<Text sx={styles.panelContentText}>{data.justificativa}</Text>
										</Flex>
									</Flex>

									<Flex justifyContent="end" mt="1.25rem" mb="0.25rem">
										<Text fontSize="1rem" fontWeight="semibold">
											Data de criação: {formatDate(data.dataCriacao)}
										</Text>
									</Flex>
								</Flex>
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			</Accordion>
			<CustomModal
				icons={[{ type: 'error' }]}
				title="Você irá remover esta Etapa"
				body="Este processo não pode ser desfeito"
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Quero Remover',
						type: 'cancel',
						onClick: () => handleDelete(data.id!),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: handleGoBack,
						datatestid: 'button--cancel',
					},
				]}
			/>
		</>
	);
};

export default AccordionDataControl;
