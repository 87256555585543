/**
 *
 * ResultEvaluationForm
 *
 */

import { IResultEvaluationForm } from '.';
import CustomModal from '../CustomModal';
import ExportCaapVotingModal, { IExportCaapVotingModalData } from '../ExportCaapVotingModal';
import InputFile, { IFile } from '../InputFile';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Grid, SystemStyleObject, Text, useDisclosure } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { downloadFiles } from 'services/http/resultEvaluation';
import { IDownloadFileRequest } from 'types/resultEvaluation';
import {
	RESULT_EVALUATION_PHASES,
	RESULT_EVALUATION,
	RESULT_EVALUATION_APPEAL,
	VOTING_ENUM_TO_TEXT,
	API_DEFAULT_ERROR,
	RESULT_EVALUATION_PHASES_BUTTON,
	STEPS_ENUM,
	RESPONSIBLE_REALESE_ENUM,
	USER_ROLES,
} from 'utils/constants';
import { downloadFile } from 'utils/downloadFile';
import { formatStringPorcentageToNumber } from 'utils/formatStringPorcentageToNumber';
import { handlePermission } from 'utils/handlePermission';
import { ResponseErrors } from 'utils/parseErrors';
import { verifyCell } from 'utils/verifyCell';

interface ResultEvaluationCardProps {
	data?: IResultEvaluationForm;
	onDelete: (phase: string, id: string) => void;
	setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsListing?: React.Dispatch<React.SetStateAction<boolean>>;
	isBtnDisabled?: boolean;
	isBtnFaseDisabeld?: boolean;
}

const ResultEvaluationCard = ({
	data,
	onDelete,
	setIsEditing,
	setIsListing,
	isBtnDisabled,
	isBtnFaseDisabeld,
}: ResultEvaluationCardProps) => {
	const NUMBER_OF_LINES = 3;
	const styles: Record<string, SystemStyleObject> = {
		calcContainer: {
			mb: '2rem',
			flexWrap: 'wrap',
			gap: {
				base: '1.5rem',
				lg: '3.375rem',
			},
			w: {
				base: '100%',
				lg: 'fit-content',
			},
		},
		calcTextLabel: {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#000000',
		},
		calcTextValue: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#4C4C4C',
		},
		calcTextPontos: {
			fontSize: '1rem',
			fontWeight: 'extrabold',
			color: '#0A77D2',
		},
		maisButton: {
			fontSize: '1rem',
			fontWeight: 'bold',
			color: '#000000',
			display: 'flex',
			alignItems: 'center',
		},
		anexoContainer: {
			flexDir: 'column',
			mt: '1.5rem',
			gap: '1rem',
		},
		filesContainer: {
			gap: '14px',
			rowGap: '1rem',
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				lg: 'repeat(2, 1fr)',
				xl: 'repeat(3, 1fr)',
			},
			mb: '2rem',
		},
		buttonContainer: {
			mt: '2rem',
			justifyContent: {
				base: 'center',
				lg: 'space-between',
			},
			gap: {
				base: '1.5rem',
				lg: '2.75rem',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		buttonContent: {
			gap: {
				base: '1.5rem',
				lg: '2.75rem',
			},
			flexDir: {
				base: 'column',
				lg: 'row',
			},
		},
		button: {
			px: {
				base: 'fit-content',
				lg: '4rem',
				xl: '5.375rem',
			},
		},
	};

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenExportModal, onOpen: onOpenExportModal, onClose: onCloseExportModal } = useDisclosure();
	const { addToast } = useCustomToast();

	const {
		session: { user },
	} = useSession();

	const hasPresidentRole = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const canHandleNextPhase = handlePermission(user, data!, true);
	const canHandleCurrentPhase = handlePermission(user, data!);

	const hasPermissionCurrentPhase = hasPresidentRole ? hasPresidentRole : canHandleCurrentPhase;
	const hasPermissionNextPhase = hasPresidentRole ? hasPresidentRole : canHandleNextPhase;

	const [toggleSeeMore, setToggleSeeMore] = useState(NUMBER_OF_LINES);

	const { mutate: downloadFilesMutate } = useMutation<Blob, AxiosError<ResponseErrors>, IDownloadFileRequest>(
		data => downloadFiles(data),
		{
			onSuccess: (data, variables) => {
				const href = URL.createObjectURL(data);

				downloadFile(href, variables.fileName);

				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'Arquivo baixado com sucesso.',
				});
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
		},
	);

	const handleDownloadFile = (value: IFile) => {
		const payload: IDownloadFileRequest = {
			fileName: value.name!,
			path: value.linkBy!,
		};

		downloadFilesMutate(payload);
	};

	const { showResults, showExportButton } = useMemo(() => {
		const isLaunchPhase = data?.faseEnum === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO;

		const isValidationPhase = data?.faseEnum === RESULT_EVALUATION_PHASES.VALIDACAO_RESULTADO;

		const isFirstInstanceDecision = data?.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_PRIMEIRA_INSTANCIA;

		const isCaapDecision = data?.faseEnum === RESULT_EVALUATION_PHASES.DECISAO_CAAP;

		const isDeferred = data?.statusDeferimento === RESULT_EVALUATION.DEFERIDO;

		const isCalcRevision = data?.solicitacaoEnum === RESULT_EVALUATION_APPEAL.REVISAO_DE_CALCULO;

		const showResultsInValidation = isValidationPhase && !isDeferred;

		const showResultsInDecisionFirstInstance = isFirstInstanceDecision && isCalcRevision && isDeferred;

		const showResultsFieldsCaapDecision = isCaapDecision && isCalcRevision && isDeferred;

		const showResults =
			isLaunchPhase || showResultsInDecisionFirstInstance || showResultsInValidation || showResultsFieldsCaapDecision;

		const showExportButton = isCaapDecision && data?.etapa === STEPS_ENUM.ETAPA_UM;

		return {
			showResults,
			showExportButton,
		};
	}, [data?.etapa, data?.faseEnum, data?.solicitacaoEnum, data?.statusDeferimento]);

	const textRef = useRef<HTMLParagraphElement>(null as unknown as HTMLParagraphElement);
	const [isEllipsisActive, setIsEllipsisActive] = useState(false);

	useEffect(() => {
		textRef.current.innerText = data?.descricao ? data?.descricao : '';
		const element = textRef.current;

		setIsEllipsisActive(
			element ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight : false,
		);
	}, [data?.descricao, textRef]);

	const handleEditButton = () => {
		setIsListing?.(false);
		setIsEditing?.(true);
	};

	const handleDelete = (phase: string, id: string) => {
		onClose();
		onDelete(phase, id);
	};

	const votingModalData: IExportCaapVotingModalData = useMemo(() => {
		const unit = data?.pactuado?.split(' ').at(0);
		const sector = data?.pactuado?.split('-').at(1)?.trim();
		return {
			id: String(data?.id),
			nomeIndicador: data?.nomeIndicador!,
			unidade: unit!,
			setor: sector!,
			ano: Number(data?.ano),
			bimestre: data?.bimestre!,
			etapa: data?.etapa!,

			caapMembers: data?.votacoes?.registrosVotacao?.map(item => ({
				...item!,
				voto: VOTING_ENUM_TO_TEXT[item?.voto as keyof typeof VOTING_ENUM_TO_TEXT],
				dataAtualizacao: data?.ultimaAtualizacao,
			}))!,
		};
	}, [data]);

	const pesoCalculado = formatStringPorcentageToNumber(data?.pesoCalculado || '');

	const dataToShow = (field: keyof IResultEvaluationForm, data?: IResultEvaluationForm) => {
		if (data?.naoSeAplica || pesoCalculado < 0) return 'N/A';

		return verifyCell(formatStringPorcentageToNumber((data?.[field] as number) || ''));
	};

	return (
		<>
			{showResults && (
				<Flex sx={styles.calcContainer}>
					<Box>
						<Text sx={styles.calcTextLabel}>Resultado total</Text>
						<Text sx={styles.calcTextValue} data-testid="text--resultado">
							{dataToShow('resultadoTotal', data)}
						</Text>
					</Box>
					<Box>
						<Text sx={styles.calcTextLabel}>Desempenho</Text>
						<Text sx={styles.calcTextValue} data-testid="text--desempenho">
							{dataToShow('desempenho', data)}
						</Text>
					</Box>
					<Box>
						<Text sx={styles.calcTextLabel}>Peso</Text>
						<Text sx={styles.calcTextValue} data-testid="text--peso">
							{data?.naoSeAplica ? 'N/A' : verifyCell(pesoCalculado)}
						</Text>
					</Box>
					<Box>
						<Text sx={styles.calcTextLabel}>Pontos obtidos</Text>
						<Text sx={styles.calcTextPontos} data-testid="text--pontosObtidos">
							{dataToShow('pontosObtidos', data)}
						</Text>
					</Box>
				</Flex>
			)}

			<Box>
				<Text sx={styles.calcTextLabel}>Descrição</Text>
				<Text
					sx={styles.calcTextValue}
					ref={textRef}
					noOfLines={toggleSeeMore}
					maxW="71.5rem"
					data-testid="text--descricao"
				>
					{data?.descricao}
				</Text>

				{isEllipsisActive && (
					<Button
						variant="unstyled"
						rightIcon={toggleSeeMore === 0 ? <ChevronUpIcon /> : <ChevronDownIcon />}
						sx={styles.maisButton}
						onClick={() => setToggleSeeMore(toggleSeeMore === 0 ? NUMBER_OF_LINES : 0)}
					>
						Ver {toggleSeeMore === 0 ? 'menos' : 'mais'}
					</Button>
				)}
			</Box>

			<Flex sx={styles.anexoContainer}>
				<Text sx={styles.calcTextLabel}>Ver anexos</Text>
				<Grid sx={styles.filesContainer}>
					{data?.documentos?.length ? (
						data?.documentos?.map((item, index) => (
							<InputFile
								key={index}
								file={item}
								data-testid="input--upload"
								isListing
								onDownloadDoc={() => handleDownloadFile(item)}
							/>
						))
					) : (
						<Text sx={{ ...styles.calcTextValue, mt: '-14px' }}>Nenhum arquivo cadastrado</Text>
					)}
				</Grid>
			</Flex>

			<Box>
				<Text sx={styles.calcTextLabel}>Responsável pelo preenchimento</Text>
				<Text sx={styles.calcTextValue} data-testid="text--responsavel">
					{data?.responsavelLancamento}
				</Text>
			</Box>

			<Flex sx={styles.buttonContainer}>
				<Flex sx={styles.buttonContent}>
					{showExportButton && (
						<Button sx={styles.button} data-testid="button--submit" onClick={onOpenExportModal}>
							{
								RESULT_EVALUATION_PHASES_BUTTON[data?.etapa as keyof typeof RESULT_EVALUATION_PHASES_BUTTON][
									data?.faseEnum!
								]
							}
						</Button>
					)}

					{data?.faseEnum !== RESULT_EVALUATION_PHASES.DECISAO_CAAP && (
						<Button
							type="submit"
							disabled={data?.faseFinalizada || isBtnFaseDisabeld || !hasPermissionNextPhase}
							sx={styles.button}
							data-testid="button--submit"
						>
							{data?.faseEnum === RESULT_EVALUATION_PHASES.LANCAMENTO_RESULTADO &&
							data.tipoResponsavelLancamento === RESPONSIBLE_REALESE_ENUM.AREA_TECNICA
								? RESULT_EVALUATION_PHASES_BUTTON[data?.etapa as keyof typeof RESULT_EVALUATION_PHASES_BUTTON]
										.VALIDACAO_RESULTADO
								: RESULT_EVALUATION_PHASES_BUTTON[data?.etapa as keyof typeof RESULT_EVALUATION_PHASES_BUTTON][
										data?.faseEnum!
								  ]}
						</Button>
					)}
					<Button
						variant="secondary"
						sx={styles.button}
						onClick={handleEditButton}
						disabled={data?.faseFinalizada || isBtnDisabled || !hasPermissionCurrentPhase}
						data-testid="button--edit"
					>
						Editar Resultado
					</Button>
				</Flex>

				<Button
					variant="cancel"
					sx={styles.button}
					onClick={onOpen}
					disabled={data?.faseFinalizada || isBtnDisabled || !hasPermissionCurrentPhase}
					data-testid="button--delete"
				>
					Excluir Resultado
				</Button>
			</Flex>

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Você irá excluir este resultado."
				body="Tem certeza que deseja realizar esta ação?"
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Quero Excluir',
						type: 'cancel',
						onClick: () => handleDelete(data?.faseEnum!, String(data?.id)!),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: onClose,
						datatestid: 'button--cancel',
					},
				]}
			/>

			<ExportCaapVotingModal isOpen={isOpenExportModal} onClose={onCloseExportModal} data={votingModalData} />
		</>
	);
};

export default ResultEvaluationCard;
