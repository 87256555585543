/**
 *
 * Select
 *
 */

import { ClearIndicator } from './CustomComponents';
import { ReactNode } from 'react';
import { InfoIcon } from '@chakra-ui/icons';
import {
	Box,
	FormControl,
	FormErrorMessage,
	FormLabel,
	SystemStyleObject,
	Text,
	useStyleConfig,
	Tooltip,
	Flex,
	Checkbox,
} from '@chakra-ui/react';
import { TableEditIconEdited } from 'assets/icons';
import ReactSelect, {
	components,
	CSSObjectWithLabel,
	MenuProps,
	Props as ReactSelectProps,
	StylesConfig,
	ValueContainerProps,
	ControlProps,
} from 'react-select';

type InputStyles = {
	field: CSSObjectWithLabel;
} & SystemStyleObject;

export interface IOption {
	id?: string | number | boolean;
	value: string | number | boolean;
	label: string;
	isDisabled?: boolean;
}

interface ISelectChildrenProperties {
	props: {};
}

interface SelectProps extends ReactSelectProps {
	dataTestId?: string;
	options?: IOption[];
	label?: string;
	errorMessage?: string;
	required?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	isCalendar?: boolean;
	variant?: string;
	placeholderSelectedItems?: string;
	isEditing?: boolean;
	tooltipInfo?: string;
	isLoading?: boolean;
	labelVariant?: string;
	showLabelTooltip?: boolean;
	canClean?: boolean;
	leftIcon?: JSX.Element;
}

const Select = ({
	variant,
	options = [],
	label,
	errorMessage,
	required,
	isDisabled,
	isCalendar,
	dataTestId,
	placeholderSelectedItems = 'items selecionados',
	isMulti,
	isEditing,
	tooltipInfo,
	labelVariant,
	isLoading = false,
	showLabelTooltip = true,
	canClean = true,
	leftIcon,
	...rest
}: SelectProps) => {
	const ControlStyles: CSSObjectWithLabel = (useStyleConfig('Select', { variant }) as InputStyles)?.field;
	const customStyles: StylesConfig = {
		container: () => ({
			display: 'flex',
			flexDirection: 'column',
			height: 'fit-content',
			flex: 1,
		}),
		option: (styles, { isSelected }) => ({
			...styles,
			minHeight: '3.5rem',
			display: 'flex',
			alignItems: 'center',
			height: 'auto',
			padding: '0.83rem 1rem',
			color: isSelected ? 'var(--chakra-colors-black-800)' : 'var(--chakra-colors-black-700)',
			fontWeight: isSelected ? 'bold' : 'normal',
			background: isSelected
				? 'linear-gradient(360deg, var(--chakra-colors-gray-350) 0%, var(--chakra-colors-transparent) 45.31%)'
				: 'white',
			borderBottom: '1px solid var(--chakra-colors-gray-95)',
			':last-child': {
				borderBottom: 'none',
				paddingBottom: 'none',
			},
			':active': {
				background: 'white',
			},
			cursor: 'pointer',
		}),
		control: styles => ({
			...styles,
			...ControlStyles,
			cursor: 'pointer',
			border: errorMessage ? '1px solid var(--chakra-colors-red-400)' : '1px solid var(--chakra-colors-gray-95)',
			borderLeft: errorMessage
				? '0.375rem solid var(--chakra-colors-red-400)'
				: '1px solid var(--chakra-colors-gray-95)',

			opacity: isDisabled ? '0.4' : '1',
			boxShadow: 'none',
			':hover': {
				borderColor: 'var(--chakra-colors-gray-400)',
			},
		}),
		valueContainer: styles => ({
			...styles,
			padding: '0',
			flexWrap: 'nowrap',
			fontSize: '1rem',
			marginLeft: !!leftIcon ? '1rem' : '0',
		}),
		indicatorsContainer: styles => ({
			...styles,
			bottom: variant !== 'medium' ? '2px' : '0',
			position: 'relative',
			paddingTop: '2px',
		}),

		dropdownIndicator: styles => ({
			...styles,
			padding: '0',
		}),
		indicatorSeparator: styles => ({ ...styles, display: 'none' }),
		placeholder: styles => ({
			...styles,
			color: 'var(--chakra-colors-gray-400)',
			fontSize: '1rem',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		}),
		singleValue: styles => ({
			...styles,
			color: 'var(--chakra-colors-black)',
			fontWeight: 'var(--chakra-fontWeights-medium)',
			fontSize: '1rem',
		}),
		multiValue: styles => ({
			...styles,
			background: 'transparent',
			padding: '0',
		}),
		multiValueLabel: styles => ({
			...styles,
			color: 'var(--chakra-colors-black)',
			fontWeight: 'var(--chakra-fontWeights-medium)',
			fontSize: '1rem',
			padding: '0',
			paddingLeft: '-6px',
		}),
		multiValueRemove: styles => ({
			...styles,
			display: 'none',
		}),
	};
	const styles: Record<string, SystemStyleObject> = {
		defaultOption: {
			cursor: 'pointer',
			p: '0.9375rem 1rem',
			borderBottom: '1px solid #E1E2E5',
			':last-child': {
				borderBottom: 'none',
			},
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			fontSize: '1rem',
		},
		optionItem: {
			pl: '1rem',
		},
		selected: {
			color: 'black.800',
			fontWeight: '700',
		},
		isEditingIcon: {
			marginLeft: '0.625rem',
		},
		tooltip: {
			pb: '1rem',
			background: 'white',
			bg: 'white',
			color: 'black',
			borderWidth: '1px',
			borderColor: 'gray.300',
			borderRadius: '4px',
			fontSize: '0.8rem',
		},
		tooltip2: {
			background: 'white',
			bg: 'white',
			color: 'black',
			borderWidth: '2px',
			borderColor: 'white',
			borderRadius: '4px',
			fontSize: '0.8rem',
		},
		tooltipIcon: {
			boxSize: '0.8rem',
			ml: '0.5rem',
			color: 'gray.300',
			svg: {
				path: {
					fill: 'blue.700',
				},
				rect: {
					fill: 'blue.700',
				},
			},
		},
		labelBox: {
			H: '24px',
			maxH: '24px',
		},
	};

	const { value } = rest;

	const allOption = { label: 'Todos', value: 'Todos' };

	const Control = ({ children, ...props }: ControlProps) => (
		<components.Control {...props}>
			{!!leftIcon && leftIcon} {children}
		</components.Control>
	);

	const ValueContainer = ({ children, ...props }: ValueContainerProps) => {
		const { getValue } = props;
		const amountOfItems = getValue().length;

		if (isCalendar && value)
			return <components.ValueContainer {...props}>{value as ReactNode}</components.ValueContainer>;

		if (amountOfItems > 1) {
			const [value, selectProps] = children as ISelectChildrenProperties[][];

			const formattedChildren = [
				[
					{
						...value[0],
						props: {
							...value[0]?.props,
							children: amountOfItems === options?.length ? 'Todos' : `${amountOfItems} ${placeholderSelectedItems}`,
						},
					},
				],
				selectProps,
			];

			return <components.ValueContainer {...props}>{formattedChildren as ReactNode}</components.ValueContainer>;
		}

		return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
	};

	const selectSingleOption = (props: any) => {
		const newValue = props?.data;
		const alreadySelected = newValue.value === (value as IOption)?.value;

		if (alreadySelected && canClean) return props?.setValue('');

		return props?.selectOption(newValue);
	};

	const Option = (props: any) => {
		if (!options?.length) {
			return <Text sx={{ ...styles?.defaultOption, cursor: 'not-allowed' }}>Nenhuma opção!</Text>;
		}

		if (isMulti && props?.value === allOption?.value) {
			const allSelected = props?.getValue()?.length === options?.length;

			return (
				<Tooltip
					isDisabled={!showLabelTooltip}
					bg="white"
					placement="top-start"
					hasArrow
					sx={styles?.tooltip2}
					label={props?.label}
				>
					<Flex sx={styles.optionItem} onClick={() => (allSelected ? props.setValue([]) : props.setValue(options))}>
						<Checkbox isChecked={allSelected} id={allOption?.value} />
						<Text sx={allSelected ? { ...styles?.defaultOption, ...styles?.selected } : { ...styles?.defaultOption }}>
							{props?.label}
						</Text>
					</Flex>
				</Tooltip>
			);
		}

		if (isMulti) {
			return (
				<Tooltip
					isDisabled={!showLabelTooltip}
					bg="white"
					placement="top-start"
					hasArrow
					sx={styles?.tooltip2}
					label={props?.label}
				>
					<Flex sx={styles.optionItem} onClick={() => selectSingleOption(props)}>
						<Checkbox isChecked={props?.isSelected} id={props?.value} />
						<Text
							sx={props?.isSelected ? { ...styles?.defaultOption, ...styles?.selected } : { ...styles?.defaultOption }}
						>
							{props?.label}
						</Text>
					</Flex>
				</Tooltip>
			);
		}

		return (
			<Tooltip
				isDisabled={!showLabelTooltip}
				bg="white"
				placement="top-start"
				hasArrow
				sx={styles?.tooltip2}
				label={props?.label}
			>
				<Text
					sx={props?.isSelected ? { ...styles?.defaultOption, ...styles?.selected } : { ...styles?.defaultOption }}
					onClick={() => selectSingleOption(props)}
				>
					{props?.label}
				</Text>
			</Tooltip>
		);
	};

	const Menu = (props: MenuProps) => {
		if (isCalendar) return <></>;
		return <components.Menu {...props}>{props.children}</components.Menu>;
	};

	return (
		<FormControl sx={{ ...customStyles?.container }} isInvalid={!!errorMessage}>
			{label && (
				<FormLabel htmlFor={dataTestId} variant={labelVariant}>
					<Box sx={styles.labelBox}>
						{label} {required && <Text as="span">*</Text>}
					</Box>
					{isEditing && (
						<Box sx={styles?.isEditingIcon}>
							<TableEditIconEdited />
						</Box>
					)}
					{tooltipInfo && (
						<Tooltip bg="white" placement="top-start" hasArrow sx={styles?.tooltip} label={tooltipInfo}>
							<InfoIcon sx={styles?.tooltipIcon} />
						</Tooltip>
					)}
				</FormLabel>
			)}
			<ReactSelect
				options={isMulti ? [allOption, ...options] : options}
				styles={customStyles}
				isDisabled={isDisabled}
				hideSelectedOptions={false}
				components={{
					Control,
					ValueContainer,
					ClearIndicator,
					Option,
					Menu,
				}}
				inputId={dataTestId}
				noOptionsMessage={() => <Text as="span">Nenhum dado encontrado.</Text>}
				isMulti={isMulti}
				isLoading={isLoading}
				loadingMessage={() => 'Buscando dados...'}
				closeMenuOnSelect={!isMulti}
				{...rest}
			/>
			{errorMessage && <FormErrorMessage data-testid="text--error">{errorMessage}</FormErrorMessage>}
		</FormControl>
	);
};

export default Select;
