/**
 *
 * ReportMonitoringFilter
 *
 */

import Multiselect from '../Multiselect';
import Select from '../Select';
import { useMemo, useEffect } from 'react';
import { Box, Button, Flex, SystemStyleObject } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { getIndicators } from 'services/http/indicators';
import { getPeriodsConcat } from 'services/http/periods';
import { getSector } from 'services/http/sectors';
import { IResponseIndicators } from 'types/indicators';
import { IPeriodsConcat } from 'types/period';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import {
	API_DEFAULT_ERROR,
	BIMESTERS_OPTIONS,
	STEPS_OPTIONS,
	SECTOR_TYPE,
	BIMESTER_TOOLTIP_INFO,
} from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { parseUrlParams } from 'utils/parseUlrParams';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import * as yup from 'yup';

interface IUnit {
	id: number;
	unidade: string;
}

export interface ReportMonitoringFilterForm {
	periodo?: IOption<IPeriodsConcat>;
	etapa?: IOption;
	bimestre?: IOption[];
	unidade?: IOption[];
	pactuado?: IOption[];
	indicador?: IOption[];
}

export type ReportMonitoringFilterFormKeys = keyof ReportMonitoringFilterForm;

const PARAMS_KEYS = ['bimestre', 'etapa', 'indicador', 'pactuado', 'periodo', 'unidade'];

export type ReportMonitoringFilterProps = {
	onSubmit: (values: ReportMonitoringFilterForm) => void;
};

const ReportMonitoringFilter = ({ onSubmit }: ReportMonitoringFilterProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			backgroundColor: '#F9FAFC',
			border: '1px solid #E1E2E5',
			borderRadius: '4px',
			p: '2rem',
			flexDir: 'column',
		},
		selectsContainer: {
			mb: '2rem',
			gap: '2rem',
			flexDir: {
				base: 'column',
				'2xl': 'row',
			},
		},
		button: {
			w: {
				base: '100%',
				xl: '15rem',
			},
		},
		periodoContent: {
			maxW: {
				base: '100%',
				'2xl': '20rem',
			},
			w: '100%',
		},
		etapaContent: {
			maxW: {
				base: '100%',
				'2xl': '20rem',
			},
			w: '100%',
		},
		bimestreContent: {
			maxW: {
				base: '100%',
				'2xl': '20rem',
			},
			w: '100%',
		},
		unidadeContent: {
			maxW: {
				base: '100%',
				'2xl': '20rem',
			},
			w: '100%',
		},
		pactuadoContent: {
			width: { base: '100%', md: '100%', '2xl': '50%' },
			maxWidth: { base: '100%', md: '100%', '2xl': '50%' },
		},
		indicatorContent: {
			width: { base: '100%', md: '100%', '2xl': '50%' },
			maxWidth: { base: '100%', md: '100%', '2xl': '50%' },
		},
	};

	const optionSchema = yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.default(undefined)
		.nullable();

	const schema = yup.object().shape({
		etapa: optionSchema,
		periodo: optionSchema.required('Este campo é obrigatório.'),
		bimestre: yup.array().of(optionSchema),
		unidade: yup.array().of(optionSchema),
		pactuado: yup.array().of(optionSchema),
		indicador: yup.array().of(optionSchema),
	});

	const {
		session: { user },
	} = useSession();

	const { addToast } = useCustomToast();
	const [searchParams] = useSearchParams();
	const params = parseUrlParams<ReportMonitoringFilterFormKeys>(PARAMS_KEYS, searchParams);

	const defaultValues = useMemo(() => {
		const etapa = STEPS_OPTIONS.find(step => step.value === params.etapa);
		return {
			etapa,
		};
	}, [params]);

	const {
		handleSubmit,
		formState: { errors },
		control,
		watch,
		resetField,
		setValue,
	} = useForm<ReportMonitoringFilterForm>({
		resolver: yupResolver(schema),
		defaultValues,
	});

	const { data: periodoConcatPenalty } = useQuery<IPeriodsConcat[], AxiosError<ResponseErrors>>(
		['periods-penalties'],
		() => getPeriodsConcat(),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const periodo = watch('periodo');

	const parsedPeriods = useMemo(() => {
		return parsedOptionArray<IPeriodsConcat>(periodoConcatPenalty, 'periodoConcatenado', 'periodoConcatenado');
	}, [periodoConcatPenalty]);

	const stepFieldData = watch('etapa')?.value;

	const selectedStep = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION],
		undefined: SECTOR_TYPE.ALL,
	};

	const { data: agreeds, isLoading: isAgreedsLoading } = useQuery<ISector[], AxiosError<ResponseErrors>>(
		['agreeds', stepFieldData],
		() => getSector(selectedStep[stepFieldData as keyof typeof selectedStep], stringifyCurrentUserUnits(user)),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
			enabled: !!user?.unidadePerfis?.length,
		},
	);

	const { data: indicadores, isLoading: isLoadindIndicators } = useQuery<
		IResponseIndicators,
		AxiosError<ResponseErrors>
	>(['indicators'], () => getIndicators(), {
		onError: ({ response }) => {
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data?.message || API_DEFAULT_ERROR,
			});
		},
	});

	const unidades: IUnit[] = useMemo(() => {
		const map = new Map();
		const _unidades: IUnit[] = [];

		agreeds?.forEach(item => {
			if (!map.has(item.idUnidade)) map.set(item.idUnidade, item.sigla);
		});

		map.forEach((value, key) => _unidades.push({ id: key, unidade: value }));

		return _unidades;
	}, [agreeds]);

	const unidade = watch('unidade');

	const pactuados = useMemo(() => {
		if (!unidade?.length) return agreeds;

		if (!agreeds?.length) return;

		const _pactuado: ISector[] = [];

		unidade?.forEach(unit => {
			_pactuado.push(...agreeds.filter(item => item?.sigla?.startsWith(unit?.label)));
		});

		return _pactuado;
	}, [agreeds, unidade]);

	useEffect(() => {
		const periodo = parsedPeriods?.find(periodo => params.periodo?.includes(periodo.value as string));
		const _indicadores = parsedOptionArray(indicadores?.content, 'nome', 'id').filter(indicador =>
			params.indicador?.includes(String(indicador.value)),
		);
		const _pactuados = parsedOptionArray(pactuados, 'nome', 'idSetor')?.filter(pactuado =>
			params.pactuado?.includes(String(pactuado.value)),
		);
		const _unidades = parsedOptionArray(unidades, 'unidade', 'id').filter(unidade =>
			params.unidade?.includes(String(unidade.value)),
		);

		const bimester = BIMESTERS_OPTIONS.filter(bimester => params.bimestre?.includes(bimester.value));

		setValue('bimestre', bimester);
		if (periodo) setValue('periodo', periodo as IOption<IPeriodsConcat>);
		setValue('indicador', _indicadores);
		setValue('pactuado', _pactuados);
		setValue('unidade', _unidades);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedPeriods, unidades]);

	const resetFormField = (field: keyof ReportMonitoringFilterForm, defaultValue: [] | string = []) =>
		resetField(field, { defaultValue });

	const onHandleSubmit = (values: ReportMonitoringFilterForm) => onSubmit(values);

	return (
		<Flex as="form" onSubmit={handleSubmit(onHandleSubmit)} sx={styles.container}>
			<Flex sx={styles.selectsContainer}>
				<Box sx={styles.etapaContent}>
					<Controller
						name="etapa"
						control={control}
						render={({ field: { value, onChange } }) => (
							<Select
								label="Etapa"
								placeholder="Selecione a etapa"
								options={STEPS_OPTIONS}
								onChange={e => {
									onChange(e);
									resetFormField('pactuado');
								}}
								value={value}
								dataTestId="select--etapa"
								errorMessage={errors?.etapa?.message}
							/>
						)}
					/>
				</Box>
				<Box sx={styles.periodoContent}>
					<Controller
						name="periodo"
						control={control}
						render={({ field: { value, onChange } }) => (
							<Select
								label="Período"
								options={parsedPeriods}
								placeholder="Selecione o período"
								tooltipInfo="Para escolher um período pode-se digitar para filtrar"
								onChange={onChange}
								value={value}
								dataTestId="select--periodo"
								errorMessage={errors?.periodo?.message}
								required
							/>
						)}
					/>
				</Box>
				<Box sx={styles.bimestreContent}>
					<Controller
						name="bimestre"
						control={control}
						render={({ field: { value, onChange } }) => (
							<Multiselect
								label="Bimestre"
								placeholder="Selecione o bimestre"
								tooltipInfo={BIMESTER_TOOLTIP_INFO}
								options={BIMESTERS_OPTIONS}
								onChange={onChange}
								value={value}
								dataTestId="select--bimestre"
								errorMessage={errors?.bimestre?.message}
								isDisabled={!periodo?.value}
							/>
						)}
					/>
				</Box>
				<Box sx={styles.unidadeContent}>
					<Controller
						name="unidade"
						control={control}
						render={({ field: { value, onChange } }) => (
							<Multiselect
								label="Unidade"
								placeholder="Selecione a unidade"
								options={parsedOptionArray(unidades, 'unidade', 'id')}
								onChange={onChange}
								value={value}
								dataTestId="select--unidade"
								errorMessage={errors?.unidade?.message}
								isLoading={isAgreedsLoading}
								isDisabled={!periodo?.value}
							/>
						)}
					/>
				</Box>
			</Flex>
			<Flex sx={styles.selectsContainer}>
				<Box sx={styles.pactuadoContent}>
					<Controller
						name="pactuado"
						control={control}
						render={({ field: { value, onChange } }) => (
							<Multiselect
								label="Pactuado"
								placeholder="Selecione o pactuado"
								options={parsedOptionArray(pactuados, 'nome', 'idSetor')}
								onChange={onChange}
								value={value}
								dataTestId="select--pactuado"
								errorMessage={errors?.pactuado?.message}
								isLoading={isAgreedsLoading}
								isDisabled={!periodo?.value}
							/>
						)}
					/>
				</Box>
				<Box sx={styles.indicatorContent}>
					<Controller
						name="indicador"
						control={control}
						render={({ field: { onChange, value } }) => (
							<Multiselect
								label="Indicador"
								placeholder="Selecione um indicador"
								options={parsedOptionArray(indicadores?.content, 'nome', 'id')}
								onChange={onChange}
								value={value}
								dataTestId="select--indicador"
								errorMessage={errors?.indicador?.message}
								isLoading={isLoadindIndicators}
								isClearable={false}
								isDisabled={!periodo?.value}
							/>
						)}
					/>
				</Box>
			</Flex>
			<Button sx={styles.button} type="submit" data-testid="button--submit">
				Pesquisar
			</Button>
		</Flex>
	);
};

export default ReportMonitoringFilter;
