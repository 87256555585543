import AxiosMockAdapter from 'axios-mock-adapter';
import { ROUTES } from 'config/routes';
import { ENDPOINTS } from 'services/endpoints';
import { api } from 'services/http';
import { INotifications } from 'services/http/notifications';
import { IPagination } from 'types/pagination';

export const mockNotificationsService = (delayResponse?: number) => {
	const mock = new AxiosMockAdapter(api, { delayResponse });

	mock
		.onGet(ENDPOINTS.getAllNotifications)
		.reply(200, PAGINATED_NOTIFICATIONS_MOCK)
		.onPut(ENDPOINTS.updateAllNotifications)
		.reply(200)
		.onPut(ENDPOINTS.updateMarkedNotifications)
		.reply(200)
		.onDelete(ENDPOINTS.deleteNotifications);

	mock
		.onGet(ENDPOINTS.getRecentsNotifications)
		.reply(200, NOTIFICATIONS_MOCK)
		.onPut(ENDPOINTS.updateAllNotifications)
		.reply(200)
		.onAny()
		.passThrough();
};

export const NOTIFICATIONS_MOCK: INotifications[] = [
	{
		id: 0,
		lida: true,
		situacao: 'Penalização!',
		data: '2024-01-01T17:00:00',
		descricao:
			'Foi aplicada uma penalização de "-3" pontos para o setor "Lorem Ipsum Dolor", pelo descumprimento do prazo da fase "Validação do Recurso". Clique no botão para conferir as alterações realizadas.',
		moduloEnum: {
			titulo: 'Penalização!',
			name: 'PENALIZACOES',
			path: ROUTES?.penalty,
		},
	},
	{
		id: 1,
		lida: true,
		situacao: 'Alteração de indicador',
		data: '2024-01-01T17:00:00',
		descricao:
			'O peso do indicador "Quantidade de Leitos Livres /Diário" foi alterado no sistema. Clique no botão para conferir as alterações realizadas.',
		moduloEnum: {
			titulo: 'Alteração de indicador',
			name: 'INDICADORES',
			path: ROUTES?.indicators,
		},
	},
	{
		id: 2,
		lida: true,
		situacao: 'Atenção!',
		data: '2024-01-01T17:00:00',
		descricao:
			'A fase "Validação do Recurso" da "2ª" etapa do "3º" bimestre do Pacto de Gestão Participativa OB-08 se encerra em "5" dias.',
		moduloEnum: {
			titulo: 'Atenção!',
			name: 'CONTROLE_DE_DATAS',
			path: ROUTES?.dateControl,
		},
	},
	{
		id: 3,
		lida: false,
		situacao: 'Cadastro de Pacto de Gestão',
		data: '2024-01-01T17:00:00',
		descricao:
			'O Pacto de Gestão cadastrado para a competência TR-11 foi validado. A partir de agora qualquer alteração somente poderá ser feita mediante termo aditivo.',
		moduloEnum: {
			titulo: 'Cadastro de Pacto de Gestão',
			name: 'PACTO_DE_GESTAO',
			path: ROUTES?.managementPact,
		},
	},
	{
		id: 4,
		lida: false,
		situacao: 'Pacto  validado',
		data: '2024-01-01T17:00:00',
		descricao:
			'O pacto para o setor “Diretoria Assistencial” foi validado. A partir de agora qualquer alteração somente poderá ser feita mediante termo aditivo.',
		moduloEnum: {
			titulo: 'Pacto  validado',
			name: 'PACTO_DE_GESTAO',
			path: ROUTES?.managementPact,
		},
	},
	{
		id: 8,
		lida: false,
		situacao: 'Cadastro de Pacto de Gestão',
		data: '2024-01-01T17:00:00',
		descricao:
			'O Pacto de Gestão cadastrado para a competência TR-11 foi validado. A partir de agora qualquer alteração somente poderá ser feita mediante termo aditivo.',
		moduloEnum: {
			titulo: 'Cadastro de Pacto de Gestão',
			name: 'PACTO_DE_GESTAO',
			path: ROUTES?.managementPact,
		},
	},
	{
		id: 9,
		lida: false,
		situacao: 'Cadastro de Pacto de Gestão',
		data: '2024-01-01T17:00:00',
		descricao:
			'O pacto para o setor “Diretoria Assistencial” foi validado. A partir de agora qualquer alteração somente poderá ser feita mediante termo aditivo.',
		moduloEnum: {
			titulo: 'Cadastro de Pacto de Gestão',
			name: 'PACTO_DE_GESTAO',
			path: ROUTES?.managementPact,
		},
	},
];

export const PAGINATED_NOTIFICATIONS_MOCK: IPagination<INotifications[]> = {
	content: NOTIFICATIONS_MOCK,
	page: 1,
	totalPages: 2,
	totalElements: 10,
	last: true,
	size: 5,
	number: 0,
};
