/**
 *
 * PenaltyForm
 *
 */

import CustomModal from '../CustomModal';
import InputText from '../InputText';
import InputTextarea from '../InputTextarea';
import Prompt from '../Prompt';
import Select from '../Select';
import { useMemo, useEffect, useState } from 'react';
import { Box, Button, Divider, Flex, SystemStyleObject, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getDateControlSteps } from 'services/http/dateControl';
import { getSector } from 'services/http/sectors';
import { DataControlData } from 'types/dateControl';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import {
	API_DEFAULT_ERROR,
	LOST_POINTS,
	STEPS_OPTIONS,
	SECTOR_TYPE,
	BIMESTERS_ENUM,
	VALUE_NUMBER_TO_BIMESTERS_ENUM,
	NUMBER_VALUE_TO_BIMESTERS_ENUM,
	TEXT_VALUE_NUMBER_TO_BIMESTERS_ENUM,
	DTA_PHASES_ENUM,
	BIMESTER_TOOLTIP_INFO,
	USER_ROLES,
} from 'utils/constants';
import { OTHER_PHASES } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import * as yup from 'yup';

export interface PenaltyFormData {
	fase: IOption;
	bimestre: IOption;
	dataLimite: string;
	pactuado: IOption;
	pontosPerdidos: IOption;
	etapa: IOption;
	clausula: string;
	justificativa: string;
	technicalAreaUnitId?: number;
}

export interface IPenaltyStepsParams {
	etapa: IOption;
}

interface PenaltyFormProps {
	onSubmit: (data: PenaltyFormData) => void;
	defaultValues?: PenaltyFormData;
	isEditing?: boolean;
	isLoading?: boolean;
}

const PenaltyForm = ({ onSubmit, defaultValues, isEditing = false, isLoading = false }: PenaltyFormProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			backgroundColor: '#F9FAFC',
			border: '1px solid #E1E2E5',
			borderRadius: '4px',
			p: '2rem',
			pr: '3.563rem',
			flexDir: 'column',
		},
		selectContainer: {
			gap: '2.5rem',
			mb: '1.5rem',
			flexDir: {
				base: 'column',
				xl: 'row',
			},
		},
		fasePactuadoContent: {
			maxW: {
				base: '100%',
				xl: '28rem',
			},
			w: '100%',
		},
		bimestrePontosContent: {
			maxW: {
				base: '100%',
				xl: '9.125rem',
			},
			w: '100%',
		},
		dataLimiteEtapaContent: {
			maxW: {
				base: '100%',
				xl: '9.75rem',
			},
			w: '100%',
		},
		clausulaJustificativaContainer: {
			mt: '1.5rem',
			gap: '1rem',
			flexDir: 'column',
		},
		text: {
			fontSize: '1rem',
			fontWeight: 'medium',
		},
		textarea: {
			resize: 'none',
		},
		buttonContainer: {
			mt: '2rem',
			gap: '2.5rem',
			flexDir: {
				base: 'column',
				xl: 'row',
			},
		},
		saveButton: {
			w: {
				base: '100%',
				xl: '13.688rem',
			},
		},
		cancelButton: {
			w: {
				base: '100%',
				xl: '14.625rem',
			},
		},
		divider: {
			mx: '2rem',
		},
	};

	const {
		session: { user },
	} = useSession();

	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);

	const firstStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);

	const optionSchema = yup
		.object()
		.shape({
			label: yup.string().required('Este campo é obrigatório.'),
			value: yup.string().required('Este campo é obrigatório.'),
		})
		.required('Este campo é obrigatório.')
		.default(undefined)
		.nullable();

	const schema = yup.object().shape({
		fase: optionSchema,
		bimestre: optionSchema,
		dataLimite: yup.string().required('Este campo é obrigatório.'),
		pactuado: optionSchema,
		pontosPerdidos: optionSchema,
		etapa: optionSchema,
		clausula: yup.string().required('Este campo é obrigatório.').max(360, 'Máximo de 360 caracteres.'),
		justificativa: yup.string().required('Este campo é obrigatório.').max(360, 'Máximo de 360 caracteres.'),
	});

	const {
		control,
		handleSubmit,
		watch,
		resetField,
		getValues,
		reset,
		formState: { errors, dirtyFields, isDirty, isSubmitting, isSubmitted },
		register,
	} = useForm<PenaltyFormData>({
		resolver: yupResolver(schema),
		defaultValues: {
			...defaultValues,
		},
	});

	const showPrompt = isDirty && !isSubmitting && !isSubmitted && !isCancelButtonClicked;

	const selectedStep = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION],
	};

	const etapa = getValues('etapa.value') as string;
	const selectedFase = watch('fase');

	const { data: agreeds, isLoading: isLoadingAgreeds } = useQuery<ISector[], AxiosError<ResponseErrors>>(
		['agreeds', etapa],
		() => getSector(selectedStep[etapa as keyof typeof selectedStep], stringifyCurrentUserUnits(user)),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
			enabled: !!etapa,
		},
	);

	const { data: dateControlData, isLoading: isLoadingDateControl } = useQuery<
		DataControlData[],
		AxiosError<ResponseErrors>
	>(['date-steps', etapa], () => getDateControlSteps(etapa), {
		onError: ({ response }) =>
			addToast({
				type: 'error',
				description: response?.data?.message || API_DEFAULT_ERROR,
				title: 'Tente novamente!',
			}),
		enabled: !!etapa,
	});

	const [options, optionFases] = useMemo(() => {
		const options =
			dateControlData?.map(item => {
				return {
					fases: item?.faseEnum,
					idControleDatas: item?.id,
					etapa: item?.etapa,
					bimestre: BIMESTERS_ENUM[item.prazo.bimestre as keyof typeof BIMESTERS_ENUM],
					dataLimite: formatDate(item.prazo.dataPrazo),
					faseAdicional: item?.faseAdcional,
					prazo: formatDate(item?.prazo.dataPrazo),
					clausula: item?.clausulaJustificativa,
					justificativa: item?.justificativa,
				};
			}) || [];

		const optionFases =
			options?.map(item => ({
				label: `${
					item.fases === OTHER_PHASES.value
						? item.faseAdicional
						: DTA_PHASES_ENUM[item.fases as keyof typeof DTA_PHASES_ENUM]
				} - ${item.prazo}`,
				value: item.idControleDatas,
			})) || [];

		return [options, optionFases];
	}, [dateControlData]);

	const [bimestersOptionsArray] = useMemo(() => {
		const penaltyData = selectedFase?.value;

		const hasData = options?.find(id => id?.idControleDatas === penaltyData);

		const bim = VALUE_NUMBER_TO_BIMESTERS_ENUM[hasData?.bimestre as keyof typeof VALUE_NUMBER_TO_BIMESTERS_ENUM];

		const bimestersOptionsArray = [
			{
				label:
					TEXT_VALUE_NUMBER_TO_BIMESTERS_ENUM[(Number(bim) - 1) as keyof typeof TEXT_VALUE_NUMBER_TO_BIMESTERS_ENUM],
				value: NUMBER_VALUE_TO_BIMESTERS_ENUM[(Number(bim) - 1) as keyof typeof NUMBER_VALUE_TO_BIMESTERS_ENUM],
			},
			{
				label: TEXT_VALUE_NUMBER_TO_BIMESTERS_ENUM[Number(bim) as keyof typeof TEXT_VALUE_NUMBER_TO_BIMESTERS_ENUM],
				value: NUMBER_VALUE_TO_BIMESTERS_ENUM[Number(bim) as keyof typeof NUMBER_VALUE_TO_BIMESTERS_ENUM],
			},
			{
				label:
					TEXT_VALUE_NUMBER_TO_BIMESTERS_ENUM[(Number(bim) + 1) as keyof typeof TEXT_VALUE_NUMBER_TO_BIMESTERS_ENUM],
				value: NUMBER_VALUE_TO_BIMESTERS_ENUM[(Number(bim) + 1) as keyof typeof NUMBER_VALUE_TO_BIMESTERS_ENUM],
			},
		];

		return [bimestersOptionsArray, hasData];
	}, [options, selectedFase?.value]);

	const penaltyAgreeds = useMemo(() => {
		return parsedOptionArray(agreeds, 'nome', 'idSetor');
	}, [agreeds]);

	const resetFormField = (fields: { field: keyof PenaltyFormData; defaultValue: string }[]) => {
		fields.forEach(({ field, defaultValue }) => resetField(field, { defaultValue }));
	};

	const changeFieldsWhenFaseChanges = (fase: IOption) => {
		const penaltyData = fase?.value;

		const hasData = options?.find(id => id?.idControleDatas === penaltyData);

		resetFormField([
			{
				field: 'justificativa',
				defaultValue: hasData?.justificativa || '',
			},
			{
				field: 'clausula',
				defaultValue: hasData?.clausula || '',
			},
			{
				field: 'dataLimite',
				defaultValue: hasData?.dataLimite || '',
			},
		]);
	};

	const handleOnSubmit = (values: PenaltyFormData) => {
		const technicalAreaUnitId = agreeds?.find(item => item.idSetor === Number(values.pactuado?.value))?.idUnidade;

		onSubmit({
			...values,
			technicalAreaUnitId,
		});
	};

	const handleGoBack = () => {
		onClose();
		navigate(ROUTES.penalty);

		isDirty &&
			addToast({
				type: 'error',
				title: 'Cadastro cancelado',
				description: 'As informações não salvas foram perdidas.',
			});
	};

	const handleOpenModal = () => {
		setIsCancelButtonClicked(true);
		onOpen();
	};

	const handleCloseModal = () => {
		setIsCancelButtonClicked(false);
		onClose();
	};

	const steps = useMemo(() => (firstStepPermission ? STEPS_OPTIONS : [STEPS_OPTIONS[1]]), [firstStepPermission]);

	useEffect(() => reset(defaultValues), [defaultValues, reset]);

	return (
		<Flex as="form" onSubmit={handleSubmit(handleOnSubmit)} sx={styles.container}>
			<Flex sx={styles.selectContainer}>
				<Box sx={styles.dataLimiteEtapaContent}>
					<Controller
						control={control}
						name="etapa"
						render={({ field }) => (
							<Select
								label="Etapa"
								placeholder="Selecione a etapa"
								options={steps}
								isEditing={isEditing && !!dirtyFields.etapa}
								value={field?.value}
								onChange={e => {
									field?.onChange(e);
									resetFormField([
										{
											field: 'bimestre',
											defaultValue: '',
										},
										{
											field: 'fase',
											defaultValue: '',
										},
										{
											field: 'dataLimite',
											defaultValue: '',
										},
										{
											field: 'pactuado',
											defaultValue: '',
										},
									]);
								}}
								errorMessage={errors.etapa?.message}
								dataTestId="select--etapa"
								required
							/>
						)}
					/>
				</Box>
				<Box sx={styles.fasePactuadoContent}>
					<Controller
						control={control}
						name="fase"
						render={({ field: { value, onChange } }) => (
							<Select
								label="Fase"
								placeholder="Selecione a fase"
								options={optionFases}
								isEditing={isEditing && !!dirtyFields.fase}
								value={value}
								onChange={values => {
									onChange(values);
									resetFormField([
										{
											field: 'bimestre',
											defaultValue: '',
										},
										{
											field: 'dataLimite',
											defaultValue: '',
										},
									]);
									changeFieldsWhenFaseChanges(values as IOption);
								}}
								errorMessage={errors.fase?.message}
								dataTestId="select--fase"
								isDisabled={!getValues('etapa.value')}
								isLoading={!!getValues('etapa.value') && isLoadingDateControl}
								required
							/>
						)}
					/>
				</Box>

				<Box sx={styles.bimestrePontosContent}>
					<Controller
						control={control}
						name="bimestre"
						render={({ field: { onChange, value } }) => (
							<Select
								label="Bimestre"
								placeholder="Selecione o bimestre"
								tooltipInfo={BIMESTER_TOOLTIP_INFO}
								options={bimestersOptionsArray}
								isEditing={isEditing && !!dirtyFields.bimestre}
								value={value}
								onChange={values => {
									onChange(values);
								}}
								errorMessage={errors.bimestre?.message}
								dataTestId="select--bimestre"
								isDisabled={!getValues('fase.value')}
								required
							/>
						)}
					/>
				</Box>
			</Flex>
			<Flex sx={styles.selectContainer}>
				<Box sx={styles.fasePactuadoContent}>
					<Controller
						control={control}
						name="pactuado"
						render={({ field: { onChange, value } }) => (
							<Select
								label="Pactuado"
								placeholder="Selecione o pactuado"
								options={penaltyAgreeds}
								isEditing={isEditing && !!dirtyFields.pactuado}
								value={value}
								onChange={values => {
									onChange(values);
								}}
								errorMessage={errors.pactuado?.message}
								dataTestId="select--pactuado"
								isLoading={isLoadingAgreeds && !!getValues('etapa.value')}
								required
							/>
						)}
					/>
				</Box>
				<Box sx={styles.bimestrePontosContent}>
					<Controller
						control={control}
						name="pontosPerdidos"
						render={({ field: { onChange, value } }) => (
							<Select
								label="Pontos perdidos"
								placeholder="Selecione os pontos"
								options={LOST_POINTS}
								isEditing={isEditing && !!dirtyFields.pontosPerdidos}
								onChange={values => {
									onChange(values);
								}}
								value={value}
								errorMessage={errors.pontosPerdidos?.message}
								dataTestId="select--pontosPerdidos"
								required
							/>
						)}
					/>
				</Box>
				<Box sx={styles.dataLimiteEtapaContent}>
					<InputText
						name="dataLimite"
						label="Data limite"
						placeholder="Data limite"
						isEditing={isEditing && !!dirtyFields.dataLimite}
						register={register}
						errorMessage={errors.dataLimite?.message}
						data-testid="input--dataLimite"
						isReadOnly
						fontSize="1rem"
						required
					/>
				</Box>
			</Flex>

			<Box sx={styles.divider}>
				<Divider />
			</Box>

			<Flex sx={styles.clausulaJustificativaContainer}>
				<InputTextarea
					label="Cláusula contratual"
					name="clausula"
					placeholder="Descreva a cláusula contratual"
					sx={styles.textarea}
					register={register}
					errorMessage={errors.clausula?.message}
					data-testid="input--clausula"
					maxLength={360}
					isEditing={isEditing && !!dirtyFields?.clausula}
					required
				/>
			</Flex>
			<Flex sx={styles.clausulaJustificativaContainer}>
				<InputTextarea
					label="Justificativa"
					name="justificativa"
					placeholder="Descreva a justificativa"
					sx={styles.textarea}
					register={register}
					errorMessage={errors.justificativa?.message}
					data-testid="input--justificativa"
					maxLength={360}
					isEditing={isEditing && !!dirtyFields?.justificativa}
					required
				/>
			</Flex>

			<Flex sx={styles.buttonContainer}>
				<Button type="submit" sx={styles.saveButton} isLoading={isLoading} data-testid="button--submit">
					Salvar
				</Button>
				<Button variant="secondary" sx={styles.cancelButton} onClick={handleOpenModal} data-testid="button--cancel">
					Cancelar
				</Button>
			</Flex>

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Você realmente quer cancelar?"
				body="Todas as informações inseridas serão perdidas caso você cancele."
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Quero cancelar',
						type: 'cancel',
						onClick: handleGoBack,
						datatestid: 'button--redirect',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: handleCloseModal,
						datatestid: 'button--cancel',
					},
				]}
			/>

			<Prompt when={showPrompt} />
		</Flex>
	);
};

export default PenaltyForm;
