import { createContext, ReactNode, useCallback, useMemo } from 'react';
import { useState } from 'react';
import { redirect } from 'hooks/useAxios';
import { authManager } from 'services/authManager';
import { INotifications } from 'services/http/notifications';
import { IPagination } from 'types/pagination';
import { Session } from 'types/user';

interface SessionProviderProps {
	children: ReactNode;
}

export interface SessionContextData {
	session: Session;
	updateSession: (session: Session) => void;
	startSession: (session: Session) => void;
	removeSession: () => void;
	notifications: INotifications[];
	getNotifications: (notifications: INotifications[]) => void;
	paginatedNotifications: IPagination<INotifications[]>;
	getPaginatedNotifications: (notifications: IPagination<INotifications[]>) => void;
}

export const SessionContext = createContext<SessionContextData>({} as SessionContextData);

export const SessionProvider = ({ children }: SessionProviderProps) => {
	const [session, setSession] = useState<Session>({} as Session);
	const [notifications, setNotifications] = useState<INotifications[]>([]);
	const [paginatedNotifications, setPaginatedNotifications] = useState<IPagination<INotifications[]>>(
		{} as IPagination<INotifications[]>,
	);

	const updateSession = useCallback(
		(data: Session) => {
			setSession({ ...session, ...data });
			authManager.set({ ...session, ...data });
		},
		[setSession, session],
	);

	const startSession = useCallback(
		(session: Session) => {
			setSession({
				...session,
			});
			authManager.set(session);
		},
		[setSession],
	);

	const removeSession = useCallback(async () => {
		try {
			await authManager.clear();

			redirect(`${process.env.REACT_APP_API_BASE_URL}/logout`);
		} catch (error) {
			console.error('Erro ao remover a sessão', error);
		}
	}, []);

	const getNotifications = useCallback(
		(notifications: INotifications[]) => {
			setNotifications(notifications);
		},
		[setNotifications],
	);

	const getPaginatedNotifications = useCallback(
		(notifications: IPagination<INotifications[]>) => {
			setPaginatedNotifications(notifications);
		},
		[setPaginatedNotifications],
	);

	const contextValue = useMemo(
		() => ({
			session,
			updateSession,
			startSession,
			removeSession,
			notifications,
			getNotifications,
			paginatedNotifications,
			getPaginatedNotifications,
		}),
		[
			session,
			updateSession,
			startSession,
			removeSession,
			notifications,
			getNotifications,
			paginatedNotifications,
			getPaginatedNotifications,
		],
	);

	return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
