/**
 *
 * ImportIndicator
 *
 */

import CustomCalendar from '../CustomCalendar';
import CustomModal from '../CustomModal';
import IndicatorMatrix from '../IndicatorMatrix';
import InputText from '../InputText';
import Select from '../Select';
import { useMemo, useState } from 'react';
import { InfoIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SystemStyleObject,
	Text,
	Grid,
	useDisclosure,
	Tag,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CloseXIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { addYears } from 'date-fns';
import { useIsMobile } from 'hooks/useIsMobile';
import { useCustomToast } from 'hooks/useToast';
import { isEmpty } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { IIndicatorParams, getIndicators } from 'services/http/indicators';
import { getSector } from 'services/http/sectors';
import { getAllStrategicObjective } from 'services/http/strategicObjetive';
import { IResponseIndicators } from 'types/indicators';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import { IStrategicObjective } from 'types/strategicObjetive';
import {
	API_DEFAULT_ERROR,
	DATA_CONTROL_STEPS_OPTIONS,
	SECTOR_TYPE,
	TEXTRES_DATA_CONTROL_STEPS_ENUM,
	TEXT_DATA_CONTROL_STEPS_ENUM,
} from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import * as yup from 'yup';

interface IUnit {
	id: number;
	unidade: string;
}

interface IImportIndicatorModalForm {
	nomeIndicador: string;
	etapa?: IOption;
	ano: string;
	unidade?: IOption;
	pactuado?: IOption;
	areaTecnica?: IOption;
	objetivoEstrategico: IOption;
}

interface IImportIndicatorModalProps {
	onClose: () => void;
	isOpen: boolean;
	getIndicator?: (id: string) => void;
	isLoading?: boolean;
}

const ImportIndicatorModal = ({ isOpen, onClose, getIndicator }: IImportIndicatorModalProps) => {
	const isMobile = useIsMobile(768);
	const styles: Record<string, SystemStyleObject> = {
		container: {
			maxWidth: '109rem',
			alignItems: 'center',
			padding: '2.687rem 3rem 2rem',
			margin: '1rem',
			top: { base: '160px', md: '12' },
		},
		closeIcon: { top: '3.3rem', right: '2.3rem' },
		header: {
			display: 'flex',
			flexDir: 'column',
			width: '100%',
			p: '0.7rem 1.5rem 1rem 0',
		},
		alertBox: {
			height: { base: '5.75rem', lg: '2.75rem' },
			bg: '#F5F8FF',
			border: '1px solid #C9DAFF',
			p: '1.063rem 0.813rem',
			color: 'black',
			alignItems: 'center',
			justifyContent: 'space-between',
			fontSize: '1rem',
			mb: '1.5rem',
			fontWeight: 500,
			borderRadius: '0.5rem',
		},
		formContainer: {
			flexDirection: 'column',
		},
		inputsContainer: {
			alignItems: 'center',
			gap: '1.063rem',
			flexWrap: {
				base: 'wrap',
				'2xl': 'nowrap',
			},
			mb: '1.153rem',
		},
		gridFields: {
			w: '100%',
			gap: {
				base: '2rem',
				lg: '1rem',
			},
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				md: 'repeat(2, 1fr)',
				'2xl': 'repeat(4, 1fr)',
			},
			alignItems: 'baseline',
			marginBottom: '1rem',
		},
		gridFields2: {
			w: '100%',
			gap: {
				base: '2rem',
				lg: '1rem',
			},
			gridTemplateColumns: {
				base: 'repeat(1, 1fr)',
				md: 'repeat(2, 1fr)',
				xl: 'repeat(3, 1fr)',
			},
			alignItems: 'baseline',
			marginBottom: '4rem',
		},
		button: {
			mb: '4rem',
			width: { base: '100%', md: '100%', lg: '15rem' },
		},
		accordionContainer: {
			flexDirection: 'column',
			gap: '2rem',
			marginRight: '2rem',
		},
		title: { fontSize: '1.5rem', fontWeight: 700 },
		bodyContainer: {
			p: '1rem',
			mb: '1rem',
			w: '100%',
			'::-webkit-scrollbar': {
				width: '0.3rem',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.5rem',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#68B2A0',
				borderRadius: '0.5rem',
			},
		},
		content: {
			'::-webkit-scrollbar': {
				width: '0.3rem',
				borderColor: '#68B2A0',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.5rem',
				borderColor: '#68B2A0',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#68B2A0',
				borderRadius: '0.5rem',
				borderColor: '#68B2A0',
			},
			flexDirection: 'column',
			gap: '0.2rem',
			overflowY: 'scroll',
			maxHeight: { base: '11rem', md: '20rem', lg: '25rem' },
		},
		footerContainer: { justifyContent: 'center', w: '100%', p: '0' },
		footerContent: {
			w: '100%',
			justifyContent: 'center',
			gap: { base: '1.5rem', sm: '1.875rem' },
			flexDirection: { base: 'column', sm: 'row' },
			alignItems: { base: 'center', sm: 'end' },
		},
		cardContainer: {
			borderWidth: '1px',
			borderColor: 'gray.95',
			mb: '0.1rem',
			borderRadius: '0.25rem',
			alignItems: 'center',
			padding: '1.25rem 2.5rem',
			justifyContent: 'space-between',
		},
		cardTitleBox: {
			alignItems: 'center',
			gap: '1rem',
		},
		headTag: {
			fontSize: '0.75rem',
			borderRadius: '0.75rem',
			h: '0.938rem',
			backgroundColor: 'blue.400',
			minW: isMobile ? '28.5px' : '56.5px',
		},
		cardTitle: {
			fontWeight: '500',
			fontSize: '1.125rem',
		},
		cardActionsBox: {
			gap: '1.5rem',
			flexDirection: { base: 'column', md: 'row' },
		},
		cardButton: {
			borderRadius: '1rem',
			py: '0.375rem',
			px: '1rem',
			fontSize: '0.75rem',
			fontWeight: '500',
			height: '1,687rem',
		},
		errorText: {
			fontSize: '0.9rem',
			color: 'red.400',
			mb: '0.5rem',
		},
	};

	const schema = yup.object().shape({
		nomeIndicador: yup.string(),
		etapa: yup.object().shape({
			label: yup.string(),
			value: yup.string(),
		}),
		ano: yup.string(),
		unidade: yup.object().shape({
			label: yup.string(),
			value: yup.string(),
		}),
		pactuado: yup.object().shape({
			label: yup.string(),
			value: yup.string(),
		}),
		areaTecnica: yup.object().shape({
			label: yup.string(),
			value: yup.string(),
		}),
		objetivoEstrategico: yup.object().shape({
			label: yup.string(),
			value: yup.string(),
		}),
	});

	const {
		control,
		register,
		setValue,
		getValues,
		reset,
		resetField,
		formState: { errors },
	} = useForm<IImportIndicatorModalForm>({ resolver: yupResolver(schema) });

	const {
		isOpen: isConfirmationModalOpen,
		onOpen: onConfirmationModalOpen,
		onClose: onConfirmationModalClose,
	} = useDisclosure();
	const { isOpen: isOpenExportModal, onOpen: onOpenExportModal, onClose: onCloseExportModal } = useDisclosure();

	const [selectedIndicatorId, setSelectedIndicatorId] = useState('');

	const { addToast } = useCustomToast();
	const [isInvalid, setIsInvalid] = useState(false);
	const [isAlertVisible, setIsAlertVisible] = useState(true);

	const stepFieldData = getValues('etapa.value') as string;

	const selectedStep = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION],
	};

	const { data: agreed, isLoading: isAgreedLoading } = useQuery<ISector[], AxiosError<ResponseErrors>>(
		['agreeds', stepFieldData],
		() => getSector(selectedStep[stepFieldData as keyof typeof selectedStep]),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
			enabled: !!stepFieldData,
		},
	);

	const { data: strategicObjectives, isLoading: isStrategicObjectiveLoading } = useQuery<
		IStrategicObjective[],
		AxiosError<ResponseErrors>,
		IStrategicObjective[]
	>(['strategicObjectve'], () => getAllStrategicObjective(), {
		onError: ({ response }) =>
			addToast({ title: 'Tente novamente!', description: response?.data?.message || API_DEFAULT_ERROR }),
	});

	const { data: indicators, mutate: indicatorsMutate } = useMutation<
		IResponseIndicators,
		AxiosError<ResponseErrors>,
		IIndicatorParams
	>(params => getIndicators({ ...params }), {
		onError: ({ response }) => {
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data.message || API_DEFAULT_ERROR,
			});
		},
	});

	const fieldsData = useMemo(() => {
		return {
			objectives:
				strategicObjectives?.map(item => ({
					value: item.id || '',
					label: item.objetivo || '',
				})) || [],
		};
	}, [strategicObjectives]);

	const unidades: IUnit[] = useMemo(() => {
		const map = new Map();
		const _unidades: IUnit[] = [];

		agreed?.forEach(item => {
			if (!map.has(item.idUnidade)) map.set(item.idUnidade, item.sigla);
		});

		map.forEach((value, key) => _unidades.push({ id: key, unidade: value }));

		return _unidades;
	}, [agreed]);

	const clearFields = () => {
		resetField('areaTecnica', {
			defaultValue: '',
		});
		resetField('pactuado', {
			defaultValue: '',
		});
		resetField('unidade', {
			defaultValue: '',
		});
	};

	const submitForm = () => {
		const data = getValues();
		const parseData = cleanDeep({
			idObjetivo: String(data?.objetivoEstrategico?.value || ''),
			nomeIndicador: data?.nomeIndicador || '',
			ano: [String(data?.ano || '')],
			etapa: String(data?.etapa?.value || ''),
			idUnidade: data?.unidade?.value || '',
			idSetor: String(data?.pactuado?.value || ''),
			nomeAreaTecnica: data?.areaTecnica?.label || '',
			nomePactuado: data?.pactuado?.label || '',
		});

		const isInvalid = isEmpty(parseData);

		setIsInvalid(isInvalid);
		if (!isInvalid) indicatorsMutate(parseData);
	};

	const handleCloseModal = () => {
		reset();
		onClose();
	};

	const handleCloseConfirmationModal = () => {
		reset();
		onClose();
		onConfirmationModalClose();
	};

	const handleImport = (indicatorId: string) => {
		reset();
		onConfirmationModalClose();
		getIndicator?.(indicatorId);
		onClose();
	};

	const hiddenAlert = () => setIsAlertVisible(false);

	return (
		<>
			<Modal isCentered isOpen={isOpen} onClose={handleCloseModal}>
				<ModalOverlay />
				<ModalContent sx={styles.container} data-testid="modal">
					<ModalHeader sx={styles.header}>
						<Text sx={styles.title}>Importar Indicadores</Text>
					</ModalHeader>
					<ModalCloseButton size="lg" sx={styles.closeIcon} />
					<ModalBody sx={styles.bodyContainer} data-testid="modal-body">
						{isAlertVisible && (
							<Flex sx={styles.alertBox}>
								<Flex sx={{ alignItems: 'center', gap: '1.063rem' }}>
									<InfoIcon color="#3498DB" />
									<Box>
										<b>Aviso:</b> O sistema exige que ao menos um filtro seja selecionado para pesquisa.
									</Box>
								</Flex>
								<CloseXIcon color="rgba(0, 0, 0, 0.6)" size="20" onClick={hiddenAlert} />
							</Flex>
						)}
						<Flex sx={styles.formContainer}>
							<Grid sx={styles.gridFields}>
								<InputText
									name="nomeIndicador"
									label="Nome do indicador"
									placeholder="Indicador"
									labelVariant="bold"
									register={register}
									data-testid={`input--nome-indicador`}
									fontSize="1rem"
								/>
								<Controller
									name="etapa"
									control={control}
									render={({ field }) => (
										<Select
											label="Etapa"
											dataTestId="input--step"
											placeholder="Selecione uma etapa"
											value={field?.value}
											onChange={e => {
												field?.onChange(e);
												clearFields();
											}}
											labelVariant="bold"
											options={DATA_CONTROL_STEPS_OPTIONS}
										/>
									)}
								/>
								<CustomCalendar
									label="Ano"
									placeholder="Selecione um ano"
									fieldName="ano"
									dataTestId="input--year"
									errorMessage={errors.ano?.message}
									setValue={setValue}
									maxDate={addYears(new Date(), 5)}
									minDate={new Date()}
									control={control}
									labelVariant="bold"
								/>

								<Controller
									name="unidade"
									control={control}
									render={({ field }) => (
										<Select
											label="Unidade"
											dataTestId="input--unit"
											placeholder="Selecione uma unidade"
											value={field?.value}
											onChange={field?.onChange}
											labelVariant="bold"
											options={parsedOptionArray(unidades, 'unidade', 'id')}
											isLoading={isAgreedLoading}
											isDisabled={!stepFieldData}
										/>
									)}
								/>
							</Grid>
							<Grid sx={styles.gridFields2}>
								<Controller
									name="pactuado"
									control={control}
									render={({ field }) => (
										<Select
											label="Pactuado"
											dataTestId="input--agreed"
											placeholder="Selecione um pactuado"
											value={field?.value}
											onChange={field?.onChange}
											labelVariant="bold"
											options={parsedOptionArray(agreed, 'nome', 'idSetor')}
											isLoading={isAgreedLoading}
											isDisabled={!stepFieldData}
										/>
									)}
								/>
								<Controller
									name="areaTecnica"
									control={control}
									render={({ field }) => (
										<Select
											label="Área técnica"
											dataTestId="input--technicalAreas"
											placeholder="Selecione uma área técnica"
											value={field?.value}
											onChange={field?.onChange}
											labelVariant="bold"
											options={parsedOptionArray(agreed, 'nome', 'idSetor')}
											isLoading={isAgreedLoading}
											isDisabled={!stepFieldData}
										/>
									)}
								/>
								<Controller
									name="objetivoEstrategico"
									control={control}
									render={({ field }) => (
										<Select
											label="Objetivo estratégico"
											dataTestId="input--strategicObjective"
											placeholder="Selecione um objetivo"
											labelVariant="bold"
											value={field?.value}
											onChange={field?.onChange}
											options={fieldsData.objectives}
											errorMessage={(errors?.objetivoEstrategico?.value?.message as string) || ''}
											isLoading={isStrategicObjectiveLoading}
										/>
									)}
								/>
							</Grid>

							{isInvalid && (
								<Text sx={styles.errorText} data-testid="text--error">
									É necessário escolher um filtro para continuar.
								</Text>
							)}
							<Button sx={styles.button} data-testid="button--search" onClick={submitForm}>
								Pesquisar
							</Button>
							<Flex sx={styles.content}>
								{indicators?.content?.map((item, index) => (
									<Flex sx={styles.accordionContainer} key={`${item?.id}-${index}`}>
										<Flex sx={styles.cardContainer}>
											<Flex sx={styles.cardTitleBox}>
												<Tag variant="solid" sx={styles.headTag}>
													{!isMobile
														? TEXT_DATA_CONTROL_STEPS_ENUM[
																String(item?.etapaEnum) as keyof typeof TEXT_DATA_CONTROL_STEPS_ENUM
														  ]
														: TEXTRES_DATA_CONTROL_STEPS_ENUM[
																String(item?.etapaEnum) as keyof typeof TEXT_DATA_CONTROL_STEPS_ENUM
														  ]}
												</Tag>
												<Text sx={styles.cardTitle}>
													{item?.nome} <b>- {item?.ano}</b>
												</Text>
											</Flex>
											<Flex sx={styles.cardActionsBox}>
												<Button
													sx={styles.cardButton}
													onClick={() => {
														onConfirmationModalOpen();
														setSelectedIndicatorId(item?.id!);
													}}
												>
													Importar Indicador
												</Button>
												<Button
													variant="secondary"
													sx={styles.cardButton}
													onClick={() => {
														onOpenExportModal();
														setSelectedIndicatorId(item?.id!);
													}}
												>
													Exportar Matriz
												</Button>
											</Flex>
										</Flex>
									</Flex>
								))}
							</Flex>
						</Flex>
					</ModalBody>
					<ModalFooter sx={styles.footerContainer}>
						<Flex sx={styles.footerContent}></Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<CustomModal
				icons={[{ type: 'info' }]}
				title="Com a importação você irá duplicar este indicador"
				body="Tem certeza que deseja continuar com esta ação?"
				isOpen={isConfirmationModalOpen}
				onClose={handleCloseConfirmationModal}
				actions={[
					{
						label: 'Importar Este Indicador',
						type: 'primary',
						onClick: () => {
							handleImport(selectedIndicatorId);
						},
						datatestid: 'button--confirm',
					},
					{
						label: 'Cancelar',
						type: 'secondary',
						onClick: handleCloseConfirmationModal,
						datatestid: 'button--cancel',
					},
				]}
			/>
			<IndicatorMatrix
				indicatorId={selectedIndicatorId}
				onClose={onCloseExportModal}
				isOpen={isOpenExportModal}
				showPactuado
			/>
		</>
	);
};

export default ImportIndicatorModal;
