/**
 *
 * Sidebar
 *
 */

import CustomModal from '../CustomModal';
import { useState } from 'react';
import { HamburgerIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { Flex, Icon, Link, SystemStyleObject, Text, Divider, IconButton, useDisclosure } from '@chakra-ui/react';
import {
	CompactSighIcon,
	DashboardIcon,
	ExitIcon,
	HelpIcon,
	HideContentIcon,
	IconProps,
	ResultMenuIcon,
	ShowContentIcon,
	SighMainLogoIcon,
} from 'assets/icons';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useIsMobile } from 'hooks/useIsMobile';
import { useSession } from 'hooks/useSession';
import { NavLink, Link as Navigation } from 'react-router-dom';
import { USER_ROLES } from 'utils/constants';

interface ISubMenu {
	label: string;
	to: string;
	customMenuStyle?: SystemStyleObject;
	accessLevels?: string[];
}

interface IMenu {
	label: string;
	to?: string;
	icon?: ({ color }: IconProps) => JSX.Element;
	customMenuStyle?: SystemStyleObject;
	children?: ISubMenu[];
}
const Sidebar = () => {
	const isMobile = useIsMobile(992);
	const { removeSession } = useSession();
	const [isHamburguerMenuOpen, setIsHamburguerMenuOpen] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const onToggleHamburguerMenu = () => {
		setIsHamburguerMenuOpen(prev => !prev);
	};

	const styles: Record<string, SystemStyleObject> = {
		container: {
			display: 'flex',
			flexDir: 'column',
			flex: 1,
			height: '100vh',
			minWidth: '20rem',
			bgColor: '#004A73',
			position: 'fixed',
			overflowY: 'auto',
			overflowX: 'hidden',
			'::-webkit-scrollbar': {
				width: '1rem',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.4rem',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#497C98',
				borderRadius: '0.4rem',
			},
			zIndex: '102',
		},
		containerHamburguer: {
			flexDir: 'column',
			bgColor: isHamburguerMenuOpen ? '#004A73' : '#fff',
			position: isHamburguerMenuOpen ? 'fixed' : 'absolute',
			zIndex: '99',
			borderRadius: '0 0.375rem 0.375rem 0',
			width: isHamburguerMenuOpen ? '21rem' : '6rem',
			height: isHamburguerMenuOpen ? '-webkit-fill-available' : 'auto',
			overflowY: isHamburguerMenuOpen ? 'auto' : 'hidden',
			'::-webkit-scrollbar': {
				width: '1rem',
			},
			'::-webkit-scrollbar-track': {
				background: '#EFEFEF',
				borderRadius: '0.4rem',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#497C98',
				borderRadius: '0.4rem',
			},
		},
		logoContainer: {
			justifyContent: 'center',
			mt: '1.5rem',
		},
		logoContainerCompact: {
			justifyContent: 'start',
			mt: '1.5rem',
			marginLeft: '1.25rem',
			marginBottom: '1rem',
		},
		menuContainer: {
			mt: '3.75rem',
			flexDir: 'column',
			color: 'gray.100',
			mb: '4rem',
		},
		menuItemContent: {
			flexDir: 'column',
			w: '100%',
			color: 'gray.100',
		},
		divider: {
			m: '0 2rem',
			marginBottom: '1rem',
			py: '0.01rem',
			pl: '0.01rem',
			backgroundColor: '#fff',
			fontWeight: 'medium',
		},
		footerContainer: {
			minW: '17.75rem',
			mb: '3rem',
			justifyContent: 'center',
			flexDirection: 'column',
			alignItems: 'center',
			marginTop: '3rem',
		},
		contactBox: {
			padding: '1rem',
			gap: '0.8rem',
			alignItems: 'center',
			marginLeft: '1rem',
			minHeight: '2.25rem',
			cursor: 'pointer',
			_hover: {
				textDecoration: 'none',
				backgroundColor: '#497C98',
				borderRadius: '0.25rem',
				width: '17.625rem',
				minHeight: '2.25rem',
			},
			_activeLink: {
				textDecoration: 'none',
				backgroundColor: '#497C98',
				borderRadius: '0.25rem',
				width: '17.625rem',
				minHeight: '2.25rem',
			},
		},
		helpText: {
			padding: '1rem',
			gap: '0.8rem',
			justifyContent: 'flex-start',
			height: '2.25rem',
			alignItems: 'center',
		},
		textLink: {
			marginBottom: '0.8rem',
			color: '#fff',
			width: '90%',
			marginLeft: '1rem',
			minHeight: '2.25rem',
			_hover: {
				textDecoration: 'none',
				backgroundColor: '#497C98',
				borderRadius: '0.25rem',
				width: '17.625rem',
				minHeight: '2.25rem',
			},
			_activeLink: {
				textDecoration: 'none',
				backgroundColor: '#497C98',
				borderRadius: '0.25rem',
				width: '17.625rem',
				minHeight: '2.25rem',
			},
		},
		routes: {
			marginBottom: '0.8rem',
			color: '#fff',
			width: '100%',
			marginLeft: '1.25rem',
			minHeight: '2.25rem',
			_hover: {
				textDecoration: 'none',
				backgroundColor: '#497C98',
				borderRadius: '0.25rem',
				width: '17.625rem',
				minHeight: '2.25rem',
			},
			_activeLink: {
				textDecoration: 'none',
				backgroundColor: '#497C98',
				borderRadius: '0.25rem',
				width: '17.625rem',
				minHeight: '2.25rem',
			},
		},
		bodyCustomStyle: {
			display: 'flex',
			justifyContent: 'center',
		},
		bodyTextCustomStyle: {
			textAlign: 'left',
		},
	};

	const menus: IMenu[] = [
		{
			label: 'Dashboard',
			to: ROUTES.home,
			icon: DashboardIcon,
			customMenuStyle: styles.routes,
		},
		{
			label: 'Tela de Resultados',
			to: ROUTES.results,
			icon: ResultMenuIcon,
			customMenuStyle: styles.routes,
		},
		{
			label: 'Estratégia',
			children: [
				{
					label: 'Planejamento Estratégico',
					to: ROUTES.strategicPlanning,
					accessLevels: [
						USER_ROLES.PRESIDENTE,
						USER_ROLES.AREA_TECNICA,
						USER_ROLES.PACTUADO,
						USER_ROLES.SERVIDOR,
						USER_ROLES.REPRESENTANTE,
					],
				},
				{
					label: 'Objetivos Estratégicos',
					to: ROUTES.strategicObjectives,
					accessLevels: [
						USER_ROLES.PRESIDENTE,
						USER_ROLES.AREA_TECNICA,
						USER_ROLES.PACTUADO,
						USER_ROLES.SERVIDOR,
						USER_ROLES.REPRESENTANTE,
					],
				},
			],
		},
		{
			label: 'Pacto de Gestão',
			children: [
				{
					label: 'Indicadores',
					to: ROUTES.indicators,
					accessLevels: [
						USER_ROLES.PRESIDENTE,
						USER_ROLES.AREA_TECNICA,
						USER_ROLES.PACTUADO,
						USER_ROLES.SERVIDOR,
						USER_ROLES.REPRESENTANTE,
					],
				},
				{
					label: 'Faixa de Desempenho',
					to: ROUTES.performanceRange,
					accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.REPRESENTANTE],
				},
				{
					label: 'Pactos',
					to: ROUTES.managementPact,
					accessLevels: [
						USER_ROLES.PRESIDENTE,
						USER_ROLES.AREA_TECNICA,
						USER_ROLES.PACTUADO,
						USER_ROLES.SERVIDOR,
						USER_ROLES.REPRESENTANTE,
					],
				},
				{
					label: 'Controle de Datas',
					to: ROUTES.dateControl,
					accessLevels: [
						USER_ROLES.PRESIDENTE,
						USER_ROLES.AREA_TECNICA,
						USER_ROLES.PACTUADO,
						USER_ROLES.SERVIDOR,
						USER_ROLES.REPRESENTANTE,
					],
				},
				{
					label: 'Penalidades',
					to: ROUTES.penalty,
					accessLevels: [
						USER_ROLES.PRESIDENTE,
						USER_ROLES.AREA_TECNICA,
						USER_ROLES.PACTUADO,
						USER_ROLES.SERVIDOR,
						USER_ROLES.REPRESENTANTE,
					],
				},
				{
					label: 'Representantes',
					to: ROUTES.representatives,
					accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.REPRESENTANTE],
				},
				{
					label: 'Membros CAAP',
					to: ROUTES.caapMembers,
					accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.SERVIDOR],
				},
				{
					label: 'Resultados Consolidados',
					to: ROUTES.reports,
					accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.SERVIDOR],
				},
			],
		},
		// {
		// 	label: 'Melhoria Contínua',
		// 	children: [
		// 		{
		// 			label: 'Análise Crítica',
		// 			to: ROUTES.home,
		// 			accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.SERVIDOR],
		// 		},
		// 		{
		// 			label: 'Plano de Ação',
		// 			to: ROUTES.home,
		// 			accessLevels: [USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO, USER_ROLES.SERVIDOR],
		// 		},
		// 	],
		// },
	];

	return (
		<>
			{isMobile ? (
				<Flex sx={styles?.containerHamburguer}>
					<Flex
						gap={'1rem'}
						justifyContent={!isHamburguerMenuOpen ? 'center' : 'flex-end'}
						height={!isHamburguerMenuOpen ? '3.9rem' : '1rem'}
						alignItems={!isHamburguerMenuOpen ? 'center' : 'baseline'}
					>
						<IconButton
							icon={!isHamburguerMenuOpen ? <HamburgerIcon /> : <SmallCloseIcon />}
							aria-label={'hamburguer-icon'}
							variant={'unstyled'}
							onClick={onToggleHamburguerMenu}
							color={isHamburguerMenuOpen ? '#fff' : '#004A73'}
							padding={isHamburguerMenuOpen ? '2.3rem' : '0'}
						/>
					</Flex>

					<Flex direction={'column'}>
						{isHamburguerMenuOpen && (
							<Flex sx={styles.logoContainerCompact}>
								<Navigation to={ROUTES.home}>
									<CompactSighIcon />
								</Navigation>
							</Flex>
						)}

						{isHamburguerMenuOpen &&
							menus.map((item, index) => (
								<Menu
									key={index}
									label={item?.label}
									children={item?.children}
									icon={item?.icon}
									to={item?.to}
									customMenuStyle={item?.customMenuStyle}
								/>
							))}
					</Flex>
					{isHamburguerMenuOpen && (
						<>
							<Flex>
								<Divider sx={styles.divider} />
							</Flex>
							<Flex sx={styles.contactBox} onClick={onOpen}>
								<HelpIcon />
								<Text color={'#fff'} fontSize={'1rem'}>
									Preciso de ajuda
								</Text>
							</Flex>
							<Link sx={styles.textLink} onClick={removeSession}>
								<Flex sx={styles.helpText}>
									<ExitIcon />
									<Text color={'#fff'} fontSize={'1rem'}>
										Sair
									</Text>
								</Flex>
							</Link>
							<Flex sx={styles.footerContainer}>
								<Flex justifyContent={'center'}>
									<Text color={'#fff'} fontSize={'0.875rem'} position={'absolute'}>
										Versão 2022.2.10
									</Text>
								</Flex>
							</Flex>
						</>
					)}
				</Flex>
			) : (
				<Flex sx={styles.container}>
					<Flex sx={styles.logoContainer}>
						<Navigation to={ROUTES.home}>
							<SighMainLogoIcon />
						</Navigation>
					</Flex>
					<Flex sx={styles.menuContainer}>
						<Flex sx={styles.menuItemContent}>
							{menus.map((item, index) => (
								<Menu
									key={index}
									label={item?.label}
									children={item?.children}
									icon={item?.icon}
									to={item?.to}
									customMenuStyle={item?.customMenuStyle}
								/>
							))}
						</Flex>
					</Flex>

					<Flex mt="auto">
						<Divider sx={styles.divider} />
					</Flex>
					<Flex sx={styles.contactBox} onClick={onOpen}>
						<HelpIcon />
						<Text color={'#fff'} fontSize={'1rem'}>
							Preciso de ajuda
						</Text>
					</Flex>
					<Link sx={styles.textLink} onClick={removeSession}>
						<Flex sx={styles.helpText}>
							<ExitIcon />
							<Text color={'#fff'} fontSize={'1rem'}>
								Sair
							</Text>
						</Flex>
					</Link>

					<Flex sx={styles.footerContainer}>
						<Flex justifyContent={'center'}>
							<Text color={'#fff'} fontSize={'0.875rem'} position={'absolute'}>
								Versão 2022.2.10
							</Text>
						</Flex>
					</Flex>
				</Flex>
			)}
			<CustomModal
				icons={[{ type: 'help' }]}
				title="Dados de contato e ajuda:"
				body="Zulip: Juliana Gomes de Aquino <br/> E-mail: <a href='mailto:suporte.inpacto@fhemig.mg.gov.br'>suporte.inpacto@fhemig.mg.gov.br</a>"
				bodyCustomStyle={styles.bodyCustomStyle}
				bodyTextCustomStyle={styles.bodyTextCustomStyle}
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Voltar',
						type: 'primary',
						onClick: onClose,
						datatestid: 'button--cancel',
					},
				]}
			/>
		</>
	);
};

const Menu = ({ label, children, icon, customMenuStyle, to = '' }: IMenu) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const menus = children?.filter(item => useCanViewAction(item.accessLevels));

	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
	const onToggleMenu = () => {
		setIsSubMenuOpen(prev => !prev);
	};
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flexDir: 'column',
			w: '100%',
		},
		menu: {
			alignItems: 'center',
			justifyContent: 'flex-start',
			gap: '0.5rem',
			width: 'max-content',
		},
		menuContainer: {
			display: 'flex',
			width: '100%',
			flexDirection: 'column',
			p: '0.813rem 0rem',
			rowGap: '0.5rem',
		},
		menuText: {
			cursor: 'pointer',
			borderLeft: '0.25rem solid transparent',
			fontSize: '1rem',
			p: '0rem 1.5rem',
			fontWeight: '600',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			gap: '1rem',
			color: 'gray.100',
			marginBottom: isSubMenuOpen ? '0' : '1rem',
		},
		submenu: {
			borderLeft: '0.25rem solid transparent',
			marginLeft: '1.25rem',
			width: '100%',
			fontSize: '1rem',
			color: '#fff',
			fontWeight: 'medium',
			_hover: {
				textDecoration: 'none',
				backgroundColor: '#497C98',
				borderRadius: '0.25rem',
				width: '17.625rem',
				"span[class*='chakra-text']": {
					textColor: '#F1C40F',
				},
			},
			_activeLink: {
				textDecoration: 'none',
				backgroundColor: '#497C98',
				borderRadius: '0.25rem',
				width: '17.625rem',
				"span[class*='chakra-text']": {
					textColor: '#F1C40F',
				},
			},
			"span[class*='chakra-text']": {
				textColor: 'green.550',
			},
		},
		flexText: {
			padding: '1rem',
			gap: '1rem',
			justifyContent: 'flex-start',
			height: '2.25rem',
			alignItems: 'center',
		},
	};

	return (
		<Flex sx={styles.container}>
			<Flex sx={styles.menu}>
				{menus ? (
					<Flex sx={styles?.menuContainer}>
						<Text sx={{ ...styles.menuText, ...customMenuStyle }} onClick={onToggleMenu} data-testid={`menu-${label}`}>
							{icon && <Icon as={icon} />}
							{label}
							{isSubMenuOpen ? <Icon as={HideContentIcon} /> : <Icon as={ShowContentIcon} />}
						</Text>
						{isSubMenuOpen &&
							menus?.map(sub => (
								<Link
									key={sub?.to}
									as={NavLink}
									to={sub?.to}
									sx={styles?.submenu}
									data-testid={`submenu-${sub?.label}`}
								>
									<Flex sx={styles.flexText}>
										<Text as="span">•</Text>
										{sub?.label}
									</Flex>
								</Link>
							))}
					</Flex>
				) : (
					<>
						<Link as={NavLink} to={to} sx={customMenuStyle} variant="menuLink" data-testid={`menu-${label}`}>
							{icon && <Icon as={icon} />}
							{label}
						</Link>
					</>
				)}
			</Flex>
		</Flex>
	);
};

export default Sidebar;
