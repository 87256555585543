/**
 *
 * ResultScreen
 *
 */

import { formatCalcWeight } from '../ResultEvaluationScreen';
import { useMemo, useState } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Grid, Spinner, Stack, SystemStyleObject, Text, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import PageWrapper from 'app/components/PageWrapper';
import Pagination from 'app/components/Pagination';
import ResulterFilter from 'app/components/ResulterFilter';
import ResulterIndicatorCard, { Bimester, ResulterIndicatorCardData } from 'app/components/ResulterIndicatorCard';
import ResulterPactCard, { IResulterPactEvaluation } from 'app/components/ResulterPactCard';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IResultsParamsKeys, getAllResultsConverted } from 'services/http/resultEvaluation';
import { IResponseActivePact } from 'types/managementPact';
import { IResponseAllResultsConverted } from 'types/resultEvaluation';
import { IOption } from 'types/select';
import { API_DEFAULT_ERROR, FIRST_PAGE, JAVASCRIPT_TYPES, USER_ROLES } from 'utils/constants';
import { formatDesempenhoEnum } from 'utils/formatEnum';
import { formatDecimalNumber } from 'utils/formatPerformanceNumber';
import { ResponseErrors } from 'utils/parseErrors';
import { parseUrlParams } from 'utils/parseUlrParams';
import { parseUrlFilters } from 'utils/parseUrlFilters';

interface IndicatorCardData {
	idPerspectiva: number;
	perspectiva: string;
	data: ResulterIndicatorCardData[];
}

interface IPenaltyFilter {
	etapa?: string;
	periodo?: string;
	bimestre?: string;
	pactuado?: IOption<IResponseActivePact>[];
}

const ResultScreen = () => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			flex: '1',
			flexDir: 'column',
			paddingBottom: '55px',
		},
		content: {
			flexDir: 'column',
			gap: '1rem',
			mt: '1.5rem',
		},
		title: { fontSize: '20px', fontWeight: 'bold', color: 'black.800', mb: '8px' },
		wrapper: { mb: '70px' },
		loadingSpinner: {
			mt: '3.875rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		flexContainer: { gap: '38px' },
		indicatorCardContent: {
			gridTemplateColumns: { base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' },
			gap: '2.5rem',
			rowGap: '2rem',
		},
		viewAudit: {
			justifyContent: 'end',
			mb: '1rem',
			gap: '2rem',
			flexDirection: { base: 'column', lg: 'row' },
		},
		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
		pagination: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const { addToast } = useCustomToast();
	const [searchParams, setParams] = useSearchParams();
	const [penaltyFilters] = useState<IPenaltyFilter>({} as IPenaltyFilter);
	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();

	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const navigate = useNavigate();

	const { params, queryValues } = useMemo(() => {
		const params = parseUrlParams<IResultsParamsKeys>(
			['etapa', 'bimestre', 'ano', 'anoInicio', 'anoFim', 'idIndicadores', 'idPacutados', 'page'],
			searchParams,
		);

		const idPactuados = params.idPacutados?.split(',');
		const idIndicadores = params.idIndicadores?.split(',');

		const queryValues = cleanDeep({
			etapa: params.etapa,
			bimestre: params.bimestre,
			ano: params.ano,
			anoInicio: params.anoInicio,
			anoFim: params.anoFim,
			idPacutados: String(idPactuados || ''),
			idIndicadores: String(idIndicadores || ''),
			page: Number(params?.page || FIRST_PAGE),
		});

		return { params, queryValues };
	}, [searchParams]);

	const hasParams = useMemo(() => !!Object.keys(params).length, [params]);

	const { data: allResultsConverted, isLoading: isAllResultsFilteredLoading } = useQuery<
		IResponseAllResultsConverted,
		AxiosError<ResponseErrors>
	>(
		['result-filtered', queryValues],
		() => getAllResultsConverted({ ...queryValues, pactuadoSelecionado: !!params.idIndicadores }),
		{
			onError: ({ response }) =>
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				}),
			enabled: !!hasParams,
		},
	);

	const pactuadoCardData: IResulterPactEvaluation[] = useMemo(() => {
		let parsedData: IResulterPactEvaluation[] = [];

		allResultsConverted?.resumo?.forEach(resultado => {
			const parsedPactPeriod = {
				nomePactuado: resultado.pactuado,
				indicadoresApurados: `${resultado.indicadoresApurados}/${resultado.indicadoresTotais}`,
				periodoBimestre: `${resultado.meses[0]} - ${resultado.meses[1]}`,
				totalPontos: resultado.totalPontos,
				saldo: resultado.pontosPerdidos || 0,
			};

			const pactuado = parsedData.find(item => item.nomePactuado === resultado.pactuado);
			const pactuadoIndex = parsedData.findIndex(item => item.nomePactuado === resultado.pactuado);

			if (pactuado) {
				pactuado.dataCard.push(parsedPactPeriod);
				parsedData.splice(pactuadoIndex, 1, pactuado);
			} else {
				parsedData.push({
					nomePactuado: resultado.pactuado,
					dataCard: [parsedPactPeriod],
				});
			}
		});

		return parsedData;
	}, [allResultsConverted]);

	const indicatorCardData: IndicatorCardData[] = useMemo(() => {
		let parsedData: IndicatorCardData[] = [];

		allResultsConverted?.perspectivaIndicadorDtos?.content?.forEach(perspective => {
			const value = {
				idPerspectiva: perspective.idPerspectiva,
				perspectiva: perspective.perpesctiva.toLocaleUpperCase(),
				data: [] as ResulterIndicatorCardData[],
			};

			perspective.pactuados?.forEach(pactuado => {
				pactuado.indicadoresPactuado?.forEach(indicadorPactuado => {
					const indicador = {
						indicadorId: indicadorPactuado?.idIndicadores,
						id: pactuado.idAtribuicaoPactuado,
						pactuado: pactuado.pactuado,
						indicador: indicadorPactuado.nomeIndicador,
						anoInicial: indicadorPactuado.indicadoresResultados[0].ano,
						anoFinal: indicadorPactuado.indicadoresResultados[indicadorPactuado.indicadoresResultados.length - 1].ano,
						responsavelLancamento: indicadorPactuado?.responsavelLancamento,
					};

					const bimestres: Bimester[] = [];

					indicadorPactuado.indicadoresResultados?.forEach(indicadorResultado => {
						const { pesoCalculadoPorcentagem } = formatCalcWeight(indicadorResultado.pesoCalculado);

						const bimestre = {
							id: indicadorResultado.idIndicadoresPacto,
							bimestre: indicadorResultado.bimestre,
							ano: indicadorResultado.ano,
							meta: indicadorResultado.meta,
							peso: indicadorResultado.peso,
							resultado: indicadorResultado.resultado,
							desempenho: indicadorResultado.desempenho,
							polaridade: indicadorResultado.polaridade,
							pontuacao:
								indicadorResultado?.pesoCalculado < 0 ||
								indicadorResultado?.pontuacao < 0 ||
								typeof indicadorResultado?.pontuacao !== JAVASCRIPT_TYPES?.number
									? 'N/A'
									: `${formatDecimalNumber(indicadorResultado.pontuacao)} / ${pesoCalculadoPorcentagem}`,
							faseEnum: indicadorResultado.fase,
							etapa: indicadorResultado?.etapa,
						};

						if (!bimestres?.length) {
							bimestres.push({
								...bimestre,
								desempenhoEnum: formatDesempenhoEnum(indicadorResultado.desempenho),
							});
						} else {
							bimestres.push({
								...bimestre,
								desempenhoEnum: formatDesempenhoEnum(
									indicadorResultado?.desempenho,
									bimestres[bimestres?.length - 1]?.desempenho,
								),
							});
						}
					});

					value.data.push({
						...indicador,
						bimestres,
						initialBimester: bimestres[0],
					});
				});
			});

			parsedData.push(value);
		});

		return parsedData;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params?.etapa, allResultsConverted?.perspectivaIndicadorDtos]);

	const onPaginate = (page: number) => {
		setParams(
			cleanDeep({
				...params,
				page: String(page),
			}),
		);
	};

	return (
		<>
			<Flex sx={styles.container}>
				<Flex sx={styles.wrapper}>
					<PageWrapper title="Tela de Resultados" />
				</Flex>

				<Flex sx={styles?.viewAudit}>
					{canViewAuditHistory && (
						<>
							<Button
								variant="unstyled"
								leftIcon={<RepeatClockIcon />}
								sx={styles.buttonShowModalAudit}
								onClick={onOpenAuditModal}
							>
								Histórico de Alterações
							</Button>
						</>
					)}
				</Flex>

				<Stack sx={styles.flexContainer}>
					<ResulterFilter />

					{hasParams && (
						<>
							{isAllResultsFilteredLoading ? (
								<Box sx={styles?.loadingSpinner}>
									<Spinner size="xl" color="#3EA2A2" />
								</Box>
							) : (
								<>
									{!!pactuadoCardData?.length &&
										pactuadoCardData.map(item => {
											const pactuadoId = penaltyFilters.pactuado?.find(pactuado => pactuado.label === item.nomePactuado)
												?.data?.idExternoPactuado;

											const filters = cleanDeep({
												...penaltyFilters,
												pactuado: pactuadoId,
											});

											const handleRedirectWithParams = () => {
												navigate(parseUrlFilters(ROUTES.penalty, { ...filters }));
											};

											return (
												<Flex sx={styles.content} key={item.nomePactuado}>
													<ResulterPactCard data={item} onRedirect={handleRedirectWithParams} />
												</Flex>
											);
										})}

									{indicatorCardData.map(item => (
										<Box key={item.idPerspectiva}>
											<Text sx={styles.title} data-testid={`text--resultPerspective-${item.idPerspectiva}`}>
												{item.perspectiva}
											</Text>
											<Grid sx={styles.indicatorCardContent}>
												{item.data?.map((item, index) => (
													<ResulterIndicatorCard data={item} key={index} />
												))}
											</Grid>
										</Box>
									))}
								</>
							)}
						</>
					)}
					<Flex sx={styles.pagination}>
						<Pagination
							currentPage={Number(queryValues?.page) || 0}
							onPaginate={onPaginate}
							pageSize={allResultsConverted?.perspectivaIndicadorDtos?.size}
							totalItems={allResultsConverted?.perspectivaIndicadorDtos?.totalElements}
						/>
					</Flex>
				</Stack>
			</Flex>

			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />
		</>
	);
};

export default ResultScreen;
