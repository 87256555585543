/**
 *
 * NotificationsList
 *
 */

import { useCallback, useMemo, useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Checkbox,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	SystemStyleObject,
	useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import CustomModal from 'app/components/CustomModal';
import NotificationsFilter, { INotificationsForm } from 'app/components/NotificationsFilter';
import NotificationsTable from 'app/components/NotificationsTable';
import PageWrapper from 'app/components/PageWrapper';
import Pagination from 'app/components/Pagination';
import { DeleteIcon, DoubleCheck, UnreadNotificationIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import {
	INotificationParams,
	INotifications,
	deleteMarkedNotifications,
	getAllNotificationsList,
	getRecentNotifications,
	updateMarkedNotifications,
} from 'services/http/notifications';
import { IPagination } from 'types/pagination';
import { IColumn, IRow } from 'types/table';
import { API_DEFAULT_ERROR, FIRST_PAGE, NOTIFICATIONS_MODULES, PAGE_SIZE } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { ResponseErrors } from 'utils/parseErrors';

const NotificationsList = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const [checkedData, setCheckedData] = useState<INotifications[]>([]);
	const [params, setParams] = useState<INotificationParams>({ page: FIRST_PAGE });

	const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
	const { addToast } = useCustomToast();
	const navigate = useNavigate();

	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			overflowX: 'auto',
			border: '0.5px solid',
			borderColor: '#E2E2E2',
			borderRadius: '8px',
			pl: '0.5rem',
			pr: '3.75rem',
			pb: '1.875rem',
			marginTop: '-15px',
			borderTopColor: 'transparent',
		},
		content: {
			minW: '62.5rem',
			mt: '1.5rem',
		},
		button: {
			fontSize: '0.875rem',
			h: '2.313rem',
			w: '6.875rem',
			borderRadius: '1.375rem',
		},
		spinner: {
			justifyContent: 'center',
			alignItems: 'center',
			h: '50vh',
		},
		menuContainer: {
			height: '45px',
			justifyContent: 'flex-end',
			"span[class*='chakra-button__icon']": {
				marginLeft: '8px',
			},
			marginBottom: '1rem',
		},
		menuButton: {
			width: '120px',
		},
		menuList: {
			zIndex: 10,
			position: 'absolute',
			top: '-7px',
			left: '-112px',
			border: '1px solid #E1E2E5',
			borderBottom: 'none',
			borderRadius: '3px',
			paddingTop: '0',
			paddingBottom: '0',
			marginTop: '0.2rem',
		},
		menuItem: {
			borderBottom: '1px solid #E1E2E5',
			minHeight: '48px',
			color: 'green.550',
			fontSize: '1rem',
			fontWeight: '400',
			_hover: {
				backgroundColor: 'gray.90',
			},
		},
		paginatorContainer: {
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	const { paginatedNotifications, getPaginatedNotifications, getNotifications } = useSession();

	const { isLoading: isNotificationsLoading, refetch: refetchNotifications } = useQuery<
		IPagination<INotifications[]>,
		AxiosError<ResponseErrors>
	>(['notifications-list', params], () => getAllNotificationsList({ ...params }), {
		onSuccess: data => {
			getPaginatedNotifications(data);
			getRecentNotificationsMutate();
		},
		onError: ({ response }) =>
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data.message || API_DEFAULT_ERROR,
			}),
		refetchOnWindowFocus: true,
	});

	const { mutate: getRecentNotificationsMutate } = useMutation<INotifications[], AxiosError<ResponseErrors>>(
		() => getRecentNotifications(),
		{
			onSuccess: data => {
				getNotifications(data);
			},
			onError: ({ response }) =>
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				}),
		},
	);

	const { mutate: updateMarkedReadNotificationsMutate } = useMutation<void, AxiosError<ResponseErrors>, void>(
		() => updateMarkedNotifications(checkedData, true),
		{
			onSuccess: () => {
				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'As notificações selecionadas foram marcadas como lidas.',
				});
				refetchNotifications();
				setCheckedData([]);
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const { mutate: updateMarkedUnreadNotificationsMutate } = useMutation<void, AxiosError<ResponseErrors>, void>(
		() => updateMarkedNotifications(checkedData, false),
		{
			onSuccess: () => {
				addToast({
					type: 'success',
					title: 'Sucesso!',
					description: 'As notificações selecionadas foram marcadas como não lidas.',
				});
				refetchNotifications();
				setCheckedData([]);
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const formatDeleteNotification = () => {
		const ids: number[] = [];

		checkedData.forEach(item => {
			ids.push(item?.id!);
		});

		return { ids };
	};

	const { mutate: deleteMarkedNotificationsMutation } = useMutation<void, AxiosError<ResponseErrors>, void>(
		() => deleteMarkedNotifications(formatDeleteNotification()),
		{
			onSuccess: () => {
				addToast({
					type: 'error',
					title: 'Excluído!',
					description: 'As notificações selecionadas foram excluídas.',
				});
				onCloseDeleteModal();
				refetchNotifications();
				setCheckedData([]);
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					description: response?.data?.message || API_DEFAULT_ERROR,
					title: 'Tente novamente!',
				});
				onCloseDeleteModal();
			},
		},
	);

	const { mutate: updateNotificationMutate } = useMutation<void, AxiosError<ResponseErrors>, INotifications>(
		notification => updateMarkedNotifications(Array(notification), true),
		{
			onSuccess: () => {
				refetchNotifications();
				getRecentNotificationsMutate();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const notifications = useMemo(
		() =>
			paginatedNotifications?.content?.length
				? paginatedNotifications
				: ([] as unknown as IPagination<INotifications[]>),
		[paginatedNotifications],
	);

	const allItemsLength = notifications?.content?.length;
	const allChecked = checkedData.length === allItemsLength!;
	const isIndeterminate = checkedData.some(Boolean) && !allChecked;

	const handleSelectAllChange = (checked: boolean) => {
		const allItems = notifications?.content?.map(item => item);

		setCheckedData(checked ? allItems! : []);
	};

	const columns: IColumn[] = [
		{
			label: '',
			key: 'checkbox',
			action: (
				<Checkbox
					size="lg"
					data-testid={'th-checkbox-all'}
					isIndeterminate={isIndeterminate}
					onChange={e => {
						handleSelectAllChange(e.currentTarget?.checked);
					}}
				/>
			),
		},
		{
			label: 'Situação',
			key: 'situacao',
		},
		{
			label: 'Data',
			key: 'data',
		},
		{
			label: 'Descrição',
			key: 'descricao',
		},
		{
			label: '',
			key: 'acoes',
		},
	];

	const handleRedirectAndUpdateRead = useCallback(
		(item: INotifications) => {
			updateNotificationMutate(item);
			navigate(NOTIFICATIONS_MODULES?.[item?.moduloEnum?.name!]?.path);
		},
		[updateNotificationMutate, navigate],
	);

	const dataRow: IRow[] = useMemo(() => {
		const handleCheckBoxChange = (value: INotifications) => {
			if (checkedData.includes(value)) {
				setCheckedData(checkedData.filter(i => i !== value));
			} else {
				setCheckedData([...checkedData, value]);
			}
		};

		return (
			notifications?.content?.map((item, index) => ({
				id: item.id!,
				lida: item.lida,
				checkbox: (
					<Checkbox
						size="lg"
						value={item.id}
						data-testid={`checkbox-${index}-${item.id}`}
						key={`${item.id}-${index}`}
						isChecked={checkedData.includes(item)}
						onChange={() => handleCheckBoxChange(item)}
						verticalAlign={'middle'}
					/>
				),
				situacao: item?.moduloEnum?.titulo || '',
				data: formatDate(String(item.data)),
				descricao: item.descricao,
				acoes: (
					<Button
						sx={styles.button}
						data-testid={`button-${index}-${item.id}`}
						onClick={() => handleRedirectAndUpdateRead(item)}
					>
						Conferir
					</Button>
				),
			})) || []
		);
	}, [notifications?.content, checkedData, styles.button, handleRedirectAndUpdateRead]);

	const onPaginate = (page: number) => {
		setParams({ ...params, page });
		setCurrentPage(page);
	};

	const handleDelete = () => {
		!checkedData.length
			? addToast({
					type: 'error',
					description: 'Não há nenhuma notificação selecionada.',
					title: 'Tente novamente!',
			  })
			: onOpenDeleteModal();
	};

	const handleUpdateUnread = () => {
		!checkedData.length
			? addToast({
					type: 'error',
					description: 'Não há nenhuma notificação selecionada.',
					title: 'Tente novamente!',
			  })
			: updateMarkedUnreadNotificationsMutate();
	};

	const handleUpdateRead = () => {
		!checkedData.length
			? addToast({
					type: 'error',
					description: 'Não há nenhuma notificação selecionada.',
					title: 'Tente novamente!',
			  })
			: updateMarkedReadNotificationsMutate();
	};

	const handleSubmit = (values: INotificationsForm) => {
		const parsedData: INotificationParams = cleanDeep({
			searchText: values?.searchText,
			page: FIRST_PAGE,
			size: PAGE_SIZE,
		});
		setParams({ ...parsedData });
	};

	return (
		<>
			<PageWrapper title="Todas as Notificações" />

			<Flex sx={styles.menuContainer}>
				<Menu>
					<MenuButton
						as={Button}
						leftIcon={<DoubleCheck />}
						rightIcon={<ChevronDownIcon />}
						variant="redirect"
						sx={styles.menuButton}
					>
						Ações
					</MenuButton>

					<MenuList sx={styles.menuList}>
						<Box w={'230px'}>
							<MenuItem
								icon={<DoubleCheck />}
								sx={styles.menuItem}
								onClick={handleUpdateRead}
								data-testid={'btn-items-read'}
							>
								Marcar como lido
							</MenuItem>
							<MenuItem
								icon={<UnreadNotificationIcon />}
								sx={styles.menuItem}
								onClick={handleUpdateUnread}
								data-testid={'btn-items-unread'}
							>
								Marcar como não lido
							</MenuItem>
							<MenuItem
								icon={<DeleteIcon />}
								sx={styles.menuItem}
								onClick={handleDelete}
								data-testid={'btn-items-delete'}
							>
								Excluir selecionados
							</MenuItem>
						</Box>
					</MenuList>
				</Menu>
			</Flex>

			<NotificationsFilter onSubmitForm={handleSubmit} />
			{isNotificationsLoading ? (
				<Flex sx={styles.spinner}>
					<Spinner />
				</Flex>
			) : (
				<Box sx={styles.container}>
					<Box sx={styles.content}>
						<NotificationsTable columns={columns} data={dataRow} />
						<Flex sx={styles.paginatorContainer}>
							<Pagination
								currentPage={currentPage}
								onPaginate={onPaginate}
								pageSize={PAGE_SIZE}
								totalItems={notifications?.totalElements}
							/>
						</Flex>
					</Box>
				</Box>
			)}

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Confirmar a exclusão?"
				body="Você realmente quer excluir essas notificações? Este processo não pode ser desfeito."
				isOpen={isOpenDeleteModal}
				onClose={onCloseDeleteModal}
				actions={[
					{
						label: 'Quero Excluir',
						type: 'cancel',
						onClick: () => deleteMarkedNotificationsMutation(),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: () => onCloseDeleteModal(),
						datatestid: 'button--cancel',
					},
				]}
			/>
		</>
	);
};

export default NotificationsList;
