/**
 *
 * ListManagementPacts
 *
 */

import { useMemo, useState } from 'react';
import { RepeatClockIcon } from '@chakra-ui/icons';
import {
	Button,
	Flex,
	SystemStyleObject,
	Tooltip,
	Box,
	Text,
	HStack,
	Spinner,
	useDisclosure,
	Icon,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import AgreedTable, { ICompositeColumns } from 'app/components/AgreedTable';
import AuditRecordsModal from 'app/components/AuditRecordsModal';
import CustomModal from 'app/components/CustomModal';
import IndicatorsWeightEditingModal, { IIndicatorsWeightForm } from 'app/components/IndicatorsWeightEditingModal';
import PactFilter, { PactFilterParamsKeys } from 'app/components/PactFilter';
import PageWrapper from 'app/components/PageWrapper';
import { PactDeleteIcon, PactEditIcon, RegisterIcon, TableEditWeightIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import { ROUTES } from 'config/routes';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useCustomToast } from 'hooks/useToast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IRequestIndicatorWeight, updateIndicatorsWeight } from 'services/http/indicators';
import { IManagementPactParams, deleteManagementPact, getManagementPact } from 'services/http/managementPact';
import { validateManagementPact } from 'services/http/managementPact';
import { IResponseManagementPact } from 'types/managementPact';
import { IRow } from 'types/table';
import { API_DEFAULT_ERROR, BIMESTERS_ENUM_TO_MONTHS, POLARITIES, STEPS_ENUM, USER_ROLES } from 'utils/constants';
import { formatFromStringToNumber } from 'utils/formatBimesters';
import { formatDate } from 'utils/formatDate';
import { formatDecimalNumber } from 'utils/formatPerformanceNumber';
import { maskThousands } from 'utils/Numbers';
import { ResponseErrors } from 'utils/parseErrors';
import { parseUrlParams } from 'utils/parseUlrParams';

const ListManagementPacts = () => {
	const [toggleValidation, setToggleValidation] = useState(false);
	const [pactValidationDate, setValidationDate] = useState('');
	const [chosenColumn, setChosenColumn] = useState('');

	const styles: Record<string, SystemStyleObject> = {
		pageContainer: {
			flex: '1',
			flexDir: 'column',
		},
		addManagementPact: {
			justifyContent: 'end',
			mb: '1rem',
			gap: '1rem',
			flexDirection: { base: 'column', lg: 'row' },
		},
		buttonRegisterPact: {
			px: '1rem',
			mb: '1rem',
		},
		tableContent: {
			mt: '1.125rem',
		},
		tableheader: {
			gap: '1.5rem',
		},
		tableHeading: {
			mt: '5rem',
			fontSize: '1.5rem',
			fontWeight: 'bold',
			pl: '2rem',
			mb: '-0.2rem',
		},
		editarPacto: {
			backgroundColor: '#F2F3F7',
			color: '#000000',
			fontSize: '0.75rem',
			borderRadius: '0.5rem',
			w: '7.5rem',
			h: '1.75rem',
			p: '0.5rem',
			display: 'flex',
			alignSelf: 'flex-end',
		},
		excluirPacto: {
			backgroundColor: '#C56443',
			fontSize: '0.75rem',
			borderRadius: '0.5rem',
			w: '7.5rem',
			h: '1.75rem',
			p: '0.5rem',
			display: 'flex',
			alignSelf: 'flex-end',
		},
		icon: {
			mr: '0.5rem',
		},
		spinner: {
			justifyContent: 'center',
			alignItems: 'center',
			h: '50vh',
		},
		button: {
			h: '1.688rem',
			backgroundColor: toggleValidation ? 'transparent' : 'gray.95',
			color: toggleValidation ? 'white' : 'black.800',
			border: '1px solid',
			borderColor: 'gray.95',
			borderRadius: '1rem',
			fontSize: '0.75rem',
			px: '0.5rem',
			gap: '0.5rem',
			_hover: {
				backgroundColor: 'gray.95',
				filter: 'brightness(0.9)',
			},
			_disabled: {
				opacity: '1',
				pointerEvents: 'none',
			},
			mr: 'auto',
		},
		dot: {
			h: '0.5rem',
			w: '0.5rem',
			backgroundColor: toggleValidation ? '#7BE495' : '#AEAEAE',
			borderRadius: '0.25rem',
		},
		tooltip: {
			color: '#3B3333',
			fontSize: '0.875rem',
			fontWeight: 'normal',
			border: '0.5px solid',
			borderColor: '#E1E2E5',
			borderRadius: '4px',
		},
		weightButton: {
			fontFamily: 'Roboto',
			fontSize: { base: '1rem' },
			color: 'black.800',
			fontWeight: 'bold',
			display: 'flex',
			alignItems: 'center',
		},
		buttonShowModalAudit: {
			fontSize: '1rem',
			fontWeight: 'medium',
			color: '#3EA2A2',
			display: 'flex',
			alignItems: 'center',
			_first: {
				ml: {
					base: '0',
				},
			},
		},
	};

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();
	const { addToast } = useCustomToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
	const { isOpen: isOpenAuditModal, onOpen: onOpenAuditModal, onClose: isCloseAuditModal } = useDisclosure();

	const firstStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);
	const secondStepPermission = useCanViewAction([USER_ROLES.PRESIDENTE, USER_ROLES.AREA_TECNICA, USER_ROLES.PACTUADO]);
	const canViewAuditHistory = useCanViewAction([
		USER_ROLES.PRESIDENTE,
		USER_ROLES.AREA_TECNICA,
		USER_ROLES.REPRESENTANTE,
	]);

	const { params, queryParams } = useMemo(() => {
		const params = parseUrlParams<PactFilterParamsKeys>(['etapa', 'idPactuado', 'ano', 'pactuado'], searchParams);

		const queryParams: IManagementPactParams = {
			anoPactoGestao: params.ano || '',
			etapa: params.etapa || '',
			idPactuado: params.idPactuado || '',
		};

		return { params, queryParams };
	}, [searchParams]);

	const {
		data: managementPact,
		isFetching: isFetchingManagementPact,
		refetch: refetchManagementPact,
		remove: removeManagementPact,
	} = useQuery<IResponseManagementPact, AxiosError<ResponseErrors>>(
		['pacto-gestao', queryParams],
		() => getManagementPact(queryParams),
		{
			onSuccess: data => {
				setToggleValidation(!!data?.dataValidacao);
				if (data?.dataValidacao) {
					setValidationDate(formatDate(data?.dataValidacao));
				}
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
			enabled: !!queryParams.anoPactoGestao,
		},
	);

	const { mutate: deleteManagementPactMutate } = useMutation<void, AxiosError<ResponseErrors>, string>(
		data => deleteManagementPact(data),
		{
			onSuccess: () => {
				onCloseDeleteModal();
				removeManagementPact();
			},
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const { mutate: validateManagementPactMutate, isLoading: isValidateManagementPactLoading } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		string
	>(data => validateManagementPact(data), {
		onSuccess: () => {
			setToggleValidation(true);
		},
		onError: ({ response }) => {
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data?.message || API_DEFAULT_ERROR,
			});
		},
	});

	const { mutate: updateIndicatorsWeightMutate, isError } = useMutation<
		void,
		AxiosError<ResponseErrors>,
		IRequestIndicatorWeight[]
	>(data => updateIndicatorsWeight(data), {
		onSuccess: () => {
			addToast({
				type: 'success',
				title: 'Sucesso',
				description: 'Peso de indicadores alterado com sucesso',
			});

			onClose();
			refetchManagementPact();
		},
		onError: ({ response }) => {
			addToast({
				type: 'error',
				title: 'Tente novamente!',
				description: response?.data?.message || API_DEFAULT_ERROR,
			});
		},
	});

	const handleValidateButton = () => {
		if (!toggleValidation) {
			validateManagementPactMutate(String(managementPact?.id));
		}
	};

	const handleUpdateIndicatorsWeight = (values: IIndicatorsWeightForm) => {
		const payload: IRequestIndicatorWeight[] = values.indicatorsWeight.map(item => {
			const currPeso = formatFromStringToNumber(item.peso);

			return {
				id: item.id || 0,
				peso: currPeso,
				pesoCalculado: Number(item.pesoCalculado.toFixed(4)),
			};
		});

		updateIndicatorsWeightMutate(payload);
	};

	const hasPermission = managementPact?.etapa === STEPS_ENUM.ETAPA_UM ? firstStepPermission : secondStepPermission;

	const onOpenIndicatorsModal = (value: string) => {
		if (hasPermission) {
			setChosenColumn(value);
			onOpen();
		}
	};

	const pactoParsed: IRow[] = useMemo(() => {
		return (
			managementPact?.indicadores?.map(item => {
				let periodos: any = {};

				item.atribuicaoPactuados.periodos.forEach(periodo => {
					periodos = {
						...periodos,
						[periodo.periodoAtribuicaoPactuadoEnum]: {
							meta: maskThousands(periodo.meta),
							pesoCalculado:
								periodo.pesoCalculado <= 0
									? 'N/A'
									: formatDecimalNumber(periodo.pesoCalculado * 100, undefined, 2, 'halfEven'),
						},
					};
				});

				return {
					indicador: item.nome,
					polaridade: POLARITIES[item.polaridade as keyof typeof POLARITIES],
					...periodos,
				};
			}) || []
		);
	}, [managementPact]);

	const columns = useMemo(() => {
		return (
			(managementPact &&
				managementPact?.indicadores[0].atribuicaoPactuados.periodos.map(periodo => {
					return {
						key: periodo.periodoAtribuicaoPactuadoEnum,
						label: `${
							BIMESTERS_ENUM_TO_MONTHS[periodo.periodoAtribuicaoPactuadoEnum as keyof typeof BIMESTERS_ENUM_TO_MONTHS]
						} ${periodo.ano}`,
					};
				})) ||
			[]
		);
	}, [managementPact]);

	const agreedColumns: ICompositeColumns = {
		columns: [
			{
				key: 'indicador',
				label: 'Nome do indicador',
				sub: true,
			},
			{
				key: 'polaridade',
				label: 'Polaridade',
				sub: true,
			},
			{
				key: 'botaoValidacao',
				label: 'Botão Validação',
				action: (
					<Flex gap="1rem" alignItems="center">
						<Tooltip
							label={`Validado em ${pactValidationDate}`}
							isDisabled={!toggleValidation}
							placement="top-start"
							sx={styles.tooltip}
							backgroundColor="white"
							shouldWrapChildren
							hasArrow
						>
							<Button
								sx={styles.button}
								disabled={toggleValidation || !hasPermission}
								onClick={handleValidateButton}
								data-testid="button--validateManagementPactStageI"
							>
								<Box sx={styles.dot} />
								{toggleValidation ? 'Pacto Validado' : hasPermission ? 'Validar Pacto' : 'Não validado'}
							</Button>
						</Tooltip>
						{isValidateManagementPactLoading && (
							<Flex>
								<Spinner size="sm" color="white" />
							</Flex>
						)}
					</Flex>
				),
			},
			...columns,
		],
		subColumns: [
			{
				key: 'meta',
				label: 'Meta',
			},
			{
				key: 'pesoCalculado',
				label: 'Peso',
				clickedColumn: onOpenIndicatorsModal,
				action: hasPermission ? (
					<Text sx={styles.weightButton} cursor={'pointer'}>
						<Icon as={TableEditWeightIcon} />
						Peso
					</Text>
				) : (
					<Text sx={styles.weightButton}>Peso</Text>
				),
			},
		],
	};

	const indicatorsWeightData = useMemo(() => {
		const indicatorsWeight =
			managementPact?.indicadores.map(indicador => ({
				id: indicador.atribuicaoPactuados.periodos.find(
					periodo => periodo.periodoAtribuicaoPactuadoEnum === chosenColumn,
				)?.idMetrica,
				indicador: indicador.nome,
				peso:
					indicador.atribuicaoPactuados.periodos.find(periodo => periodo.periodoAtribuicaoPactuadoEnum === chosenColumn)
						?.peso || 0,
				pesoCalculado:
					indicador.atribuicaoPactuados.periodos.find(periodo => periodo.periodoAtribuicaoPactuadoEnum === chosenColumn)
						?.pesoCalculado || 0,
			})) || [];

		return { indicatorsWeight };
	}, [chosenColumn, managementPact?.indicadores]);

	return (
		<>
			<Flex sx={styles.pageContainer}>
				<PageWrapper title="Pacto de Gestão" goBackRoute={ROUTES.home} />
				<Flex sx={styles?.addManagementPact}>
					{canViewAuditHistory && (
						<Button
							variant="unstyled"
							leftIcon={<RepeatClockIcon />}
							sx={styles.buttonShowModalAudit}
							onClick={onOpenAuditModal}
						>
							Histórico de Alterações
						</Button>
					)}
					{firstStepPermission && (
						<Button
							leftIcon={<RegisterIcon color="white" />}
							sx={styles.buttonRegisterPact}
							onClick={() => navigate(ROUTES.createManagementPact('etapa-1'))}
							data-testid="button--createManagementPactStageI"
						>
							Novo Pacto de 1ª Etapa
						</Button>
					)}
					{secondStepPermission && (
						<Button
							variant="redirect"
							leftIcon={<RegisterIcon color="#3EA2A2" />}
							sx={styles.buttonRegisterPact}
							onClick={() => navigate(ROUTES.createManagementPact('etapa-2'))}
							data-testid="button--createManagementPactStage2"
						>
							Novo Pacto de 2ª Etapa
						</Button>
					)}
				</Flex>

				<PactFilter />

				{isFetchingManagementPact ? (
					<Flex sx={styles.spinner}>
						<Spinner size="xl" color="#3EA2A2" />
					</Flex>
				) : (
					!!pactoParsed.length && (
						<>
							<HStack sx={styles.tableheader}>
								<Text sx={styles.tableHeading} data-testid="text--PactName">
									{params.pactuado}
								</Text>
								{hasPermission && (
									<Button
										sx={styles.editarPacto}
										onClick={() => navigate(ROUTES.editManagementPact(String(managementPact?.id)))}
										data-testid="button--editManagementPactStageI"
									>
										<Box sx={styles.icon}>
											<PactEditIcon />
										</Box>
										Editar Pacto
									</Button>
								)}
								{!toggleValidation && hasPermission && (
									<Button
										sx={styles.excluirPacto}
										onClick={onOpenDeleteModal}
										data-testid="button--deleteManagementPactStageI"
									>
										<Box sx={styles.icon}>
											<PactDeleteIcon color="white" />
										</Box>
										Excluir Pacto
									</Button>
								)}
							</HStack>
							<Flex sx={styles.tableContent}>
								<AgreedTable compositeColumns={agreedColumns} data={pactoParsed} />
							</Flex>
						</>
					)
				)}
			</Flex>
			<IndicatorsWeightEditingModal
				defaultValues={indicatorsWeightData}
				bimester={chosenColumn}
				isOpen={isOpen}
				onClose={onClose}
				onSubmitForm={handleUpdateIndicatorsWeight}
				hasError={isError}
			/>
			<AuditRecordsModal isOpen={isOpenAuditModal} onClose={isCloseAuditModal} />

			<CustomModal
				icons={[{ type: 'error' }]}
				title="Confirmar a exclusão?"
				body="Você realmente quer excluir este pacto de gestão? Este processo não pode ser desfeito."
				isOpen={isOpenDeleteModal}
				onClose={onCloseDeleteModal}
				actions={[
					{
						label: 'Quero Excluir',
						type: 'cancel',
						onClick: () => deleteManagementPactMutate(String(managementPact?.id)),
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: () => onCloseDeleteModal(),
						datatestid: 'button--cancel',
					},
				]}
			/>
		</>
	);
};

export default ListManagementPacts;
