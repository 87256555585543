import CustomModal from '../CustomModal';
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Box,
	Text,
	Flex,
	SystemStyleObject,
	Divider,
	useDisclosure,
	IconButton,
} from '@chakra-ui/react';
import { DeleteIcon, TableEditIcon } from 'assets/icons';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { StrategicPlanning } from 'types/strategicPlanning';
import { USER_ROLES } from 'utils/constants';

interface AccordionsProps {
	strategicPlanning: StrategicPlanning;
	editStrategicPlanning: (id: string) => void;
	deleteStrategicPlanning: (id: string) => void;
}

const Accordions = ({ strategicPlanning, editStrategicPlanning, deleteStrategicPlanning }: AccordionsProps) => {
	const styles: Record<string, SystemStyleObject> = {
		accordionItem: {
			borderWidth: '1px',
			borderColor: 'gray.95',
			mb: '0.1rem',
			borderRadius: '0.25rem',
		},
		accordionButton: {
			_hover: {},
			height: 'auto',
			paddingTop: '0.625rem',
		},
		titleContainer: {
			flex: '1',
			textAlign: 'left',
			ml: '2rem',
			alignItems: 'center',
			gap: '0.4rem',
			flexDirection: 'row',
		},
		titleDirection: {
			flexDir: {
				base: 'column',
				lg: 'row',
			},
			alignItems: {
				base: 'start',
				lg: 'center',
			},
		},
		title: {
			fontSize: { base: '1rem', sm: '1.125rem' },
			textAlign: { base: 'start', sm: 'center' },
		},
		period: {
			fontSize: { base: '1rem', sm: '1.125rem' },
			fontWeight: 'bold',
			textAlign: 'center',
			whiteSpace: 'nowrap',
		},
		titleIsExpanded: {
			fontSize: '1.25rem',
		},
		periodIsExpanded: {
			fontSize: { base: '1rem', sm: '1.125rem' },
			fontWeight: 'bold',
			textAlign: 'center',
			whiteSpace: 'nowrap',
		},
		icons: { gap: '1.5rem', paddingRight: { base: '0', md: '2rem', lg: '6rem' }, alignSelf: 'center' },
		accordionPanel: {
			ps: '2rem',
			pe: '5rem',
		},
		firstInfo: {
			w: '100%',
			mb: '1.625rem',
			fontSize: '1rem',
		},
		infosColumn: {
			justifyContent: 'space-between',
			fontSize: '1rem',
			gap: '1rem',
			flexDirection: { base: 'column', md: 'row' },
		},
		infoItem: {
			maxW: '22.5rem',
		},
		titles: {
			fontWeight: 'bold',
			fontSize: '1rem',
			mb: '1rem',
		},
		divider: {
			p: '0rem 5rem 0rem 2rem',
		},
		accordionIcon: {
			h: '2.25rem',
			w: '2.25rem',
		},
		box: {
			display: 'flex',
			flexDirection: 'row',
			alignContent: 'center',
			minH: { base: 'auto', md: '4.25rem' },
			wordBreak: 'break-word',
		},
		boxExpanded: {
			display: 'flex',
			flexDirection: 'row',
			alignContent: 'center',
			minH: { base: 'auto', md: '5rem' },
			wordBreak: 'break-word',
		},
	};

	const hasPermission = useCanViewAction([USER_ROLES.PRESIDENTE]);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleGoBack = () => {
		onClose();
	};

	const handleDelete = () => {
		deleteStrategicPlanning(strategicPlanning.id!);
		onClose();
	};

	return (
		<>
			<Accordion allowMultiple>
				<AccordionItem key={strategicPlanning.id} sx={styles.accordionItem} data-testid="strategic--planning">
					{({ isExpanded }) => (
						<>
							<Box sx={isExpanded ? styles.boxExpanded : styles.box}>
								<AccordionButton sx={styles.accordionButton} data-testid="strategic--planning-accordion-btn">
									<AccordionIcon sx={styles.accordionIcon} />
									<Flex sx={styles.titleContainer}>
										<Flex sx={styles.titleDirection}>
											<Text sx={isExpanded ? styles.titleIsExpanded : styles.title}>{strategicPlanning.nome}</Text>
											<Text
												sx={isExpanded ? styles.periodIsExpanded : styles.period}
												data-testid="strategic--planning-period"
											>
												{' — '}
												{`${strategicPlanning.anoInicial} - ${strategicPlanning.anoFinal}`}
											</Text>
										</Flex>
									</Flex>
								</AccordionButton>
								<Flex sx={styles.icons}>
									{hasPermission && (
										<IconButton
											aria-label={'Editar planejamento'}
											variant="unstyled"
											icon={<TableEditIcon />}
											data-testid="strategic--planning-edit"
											onClick={() => editStrategicPlanning(strategicPlanning.id!)}
										/>
									)}
									{hasPermission && (
										<IconButton
											aria-label={'Excluir planejamento'}
											variant="unstyled"
											icon={<DeleteIcon />}
											data-testid="strategic--planning-delete"
											onClick={onOpen}
											visibility={strategicPlanning?.podeExcluir ? 'visible' : 'hidden'}
										/>
									)}
								</Flex>
							</Box>

							<Box sx={styles.divider}>
								<Divider />
							</Box>
							<AccordionPanel sx={styles.accordionPanel}>
								<Box>
									<Box sx={styles.firstInfo}>
										<Text sx={styles.titles}>Perspectiva</Text>
										{strategicPlanning.perspectivas?.map(({ perspectiva, id }) => (
											<Text key={id} data-testid="strategic--planning-perspective">
												{perspectiva}
											</Text>
										))}
									</Box>
									<Flex sx={styles.infosColumn}>
										<Box sx={styles.infoItem}>
											<Text sx={styles.titles}>Missão</Text>
											<Text fontSize="1rem" data-testid="strategic--planning-mission">
												{strategicPlanning.missao}
											</Text>
										</Box>
										<Box sx={styles.infoItem}>
											<Text sx={styles.titles}>Visão</Text>
											<Text fontSize="1rem" data-testid="strategic--planning-vision">
												{strategicPlanning.visao}
											</Text>
										</Box>
										<Box sx={styles.infoItem}>
											<Text sx={styles.titles}>Valor</Text>
											<Text fontSize="1rem" data-testid="strategic--planning-values">
												{strategicPlanning.valor}
											</Text>
										</Box>
									</Flex>
								</Box>
							</AccordionPanel>
						</>
					)}
				</AccordionItem>
			</Accordion>
			<CustomModal
				icons={[{ type: 'error' }]}
				title="Confirmar a exclusão?"
				body="Você realmente quer Excluir este Planejamento Estratégico? Este processo não pode ser desfeito."
				isOpen={isOpen}
				onClose={onClose}
				actions={[
					{
						label: 'Quero excluir',
						type: 'cancel',
						onClick: handleDelete,
						datatestid: 'button--confirm',
					},
					{
						label: 'Voltar',
						type: 'secondary',
						onClick: handleGoBack,
						datatestid: 'button--cancel',
					},
				]}
			/>
		</>
	);
};

export default Accordions;
