/**
 *
 * Multiselect
 *
 */

import InputWrapper from '../InputWrapper';
import { Checkbox, Flex, SystemStyleObject, Text, Tooltip, useStyleConfig } from '@chakra-ui/react';
import { ClearIndicator, ValueContainer } from 'app/components/Select/CustomComponents';
import Select, { CSSObjectWithLabel, InputActionMeta, OptionProps, Props, StylesConfig } from 'react-select';
import { IOption } from 'types/select';

type InputStyles = {
	field: CSSObjectWithLabel;
} & SystemStyleObject;

interface MultiselectProps extends Props {
	options?: IOption[];
	label?: string;
	isEditing?: boolean;
	variant?: string;
	labelVariant?: string;
	dataTestId?: string;
	errorMessage?: string;
	required?: boolean;
	tooltipInfo?: string;
	showLabelTooltip?: boolean;
}

const Multiselect = ({
	options = [],
	label,
	variant,
	errorMessage,
	isDisabled,
	dataTestId,
	isEditing,
	labelVariant,
	required,
	tooltipInfo,
	showLabelTooltip = true,
	...rest
}: MultiselectProps) => {
	const ControlStyles: CSSObjectWithLabel = (useStyleConfig('Select', { variant }) as InputStyles)?.field;
	const customStyles: StylesConfig = {
		container: () => ({
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			flex: 1,
		}),
		option: (styles, { isSelected }) => ({
			...styles,
			minHeight: '3.5rem',
			display: 'flex',
			alignItems: 'center',
			height: 'auto',
			padding: '0.83rem 1rem',
			color: isSelected ? 'var(--chakra-colors-black-800)' : 'var(--chakra-colors-black-700)',
			fontWeight: isSelected ? 'bold' : 'normal',
			background: isSelected
				? 'linear-gradient(360deg, var(--chakra-colors-gray-350) 0%, var(--chakra-colors-transparent) 45.31%)'
				: 'white',
			borderBottom: '1px solid var(--chakra-colors-gray-95)',
			':last-child': {
				borderBottom: 'none',
				paddingBottom: 'none',
			},
			':active': {
				background: 'white',
			},
			cursor: 'pointer',
		}),
		control: styles => ({
			...styles,
			...ControlStyles,
			cursor: 'pointer',
			border: errorMessage ? '1px solid var(--chakra-colors-red-400)' : '1px solid var(--chakra-colors-gray-95)',
			borderLeft: errorMessage
				? '0.375rem solid var(--chakra-colors-red-400)'
				: '1px solid var(--chakra-colors-gray-95)',

			opacity: isDisabled ? '0.4' : '1',
			boxShadow: 'none',
			':hover': {
				borderColor: 'var(--chakra-colors-gray-400)',
			},
		}),
		valueContainer: styles => ({
			...styles,
			padding: '0',
			flexWrap: 'nowrap',
			fontSize: '1rem',
		}),
		indicatorsContainer: styles => ({ ...styles, bottom: variant !== 'medium' ? '2px' : '0', position: 'relative' }),
		dropdownIndicator: styles => ({
			...styles,
			padding: '0',
		}),
		indicatorSeparator: styles => ({ ...styles, display: 'none' }),
		placeholder: styles => ({
			...styles,
			color: 'var(--chakra-colors-gray-400)',
			fontSize: '1rem',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		}),
		singleValue: styles => ({
			...styles,
			color: 'var(--chakra-colors-black)',
			fontWeight: 'var(--chakra-fontWeights-medium)',
			fontSize: '1rem',
		}),
		multiValue: styles => ({
			...styles,
			background: 'transparent',
			padding: '0',
		}),
		multiValueLabel: styles => ({
			...styles,
			color: 'var(--chakra-colors-black)',
			fontWeight: 'var(--chakra-fontWeights-medium)',
			fontSize: '1rem',
			padding: '0',
			paddingLeft: '-6px',
		}),
		multiValueRemove: styles => ({
			...styles,
			display: 'none',
		}),
	};
	const styles: Record<string, SystemStyleObject> = {
		defaultOption: {
			cursor: 'pointer',
			p: '0.9375rem 1rem',
			borderBottom: '1px solid #E1E2E5',
			':last-child': {
				borderBottom: 'none',
			},
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			fontSize: '1rem',
		},
		optionItem: {
			pl: '1rem',
		},
		selected: {
			color: 'black.800',
			fontWeight: '700',
		},
		tooltip: {
			background: 'white',
			bg: 'white',
			color: 'black',
			borderWidth: '2px',
			borderColor: 'white',
			borderRadius: '4px',
			fontSize: '0.8rem',
		},
	};

	const allOption: IOption = { label: 'Todos', value: 'Todos' };

	const Option = (props: OptionProps) => {
		const typedData = props.data as IOption;

		if (!props.options?.length) {
			return <Text sx={{ ...styles?.defaultOption, cursor: 'not-allowed' }}>Nenhuma opção!</Text>;
		}

		if (typedData?.value === allOption?.value) {
			const allSelected = props?.getValue()?.length === options?.length;

			return (
				<Tooltip
					isDisabled={!showLabelTooltip}
					bg="white"
					placement="top-start"
					hasArrow
					sx={styles?.tooltip}
					label={props?.label}
				>
					<Flex
						sx={styles.optionItem}
						onClick={() =>
							allSelected ? props.setValue([], 'select-option') : props.setValue(options, 'deselect-option')
						}
					>
						<Checkbox isChecked={allSelected} id={String(allOption?.value || '')} />
						<Text sx={allSelected ? { ...styles?.defaultOption, ...styles?.selected } : { ...styles?.defaultOption }}>
							{props?.label}
						</Text>
					</Flex>
				</Tooltip>
			);
		}

		return (
			<Tooltip
				isDisabled={!showLabelTooltip}
				bg="white"
				placement="top-start"
				hasArrow
				sx={styles?.tooltip}
				label={props?.label}
			>
				<Flex sx={styles.optionItem} onClick={() => selectSingleOption(props)}>
					<Checkbox isChecked={props?.isSelected} id={String(typedData?.value || '')} />
					<Text
						sx={props?.isSelected ? { ...styles?.defaultOption, ...styles?.selected } : { ...styles?.defaultOption }}
					>
						{props?.label}
					</Text>
				</Flex>
			</Tooltip>
		);
	};

	const selectSingleOption = (props: OptionProps) => {
		const newValue = props?.data as IOption;
		const alreadySelected = newValue.value === (rest.value as IOption)?.value;

		if (alreadySelected) return props?.setValue({} as IOption, 'deselect-option');

		return props?.selectOption(newValue);
	};

	const onInputChange = (inputValue: string, { action, prevInputValue }: InputActionMeta) => {
		if (action === 'input-change') return inputValue;

		return prevInputValue;
	};

	return (
		<InputWrapper
			label={label}
			errorMessage={errorMessage}
			dataTestId={dataTestId}
			isEditing={isEditing}
			labelVariant={labelVariant}
			required={required}
			tooltipInfo={tooltipInfo}
		>
			<Select
				options={[allOption, ...options]}
				isMulti={true}
				components={{
					ValueContainer,
					ClearIndicator,
					Option,
				}}
				onInputChange={onInputChange}
				styles={customStyles}
				inputId={dataTestId}
				isDisabled={isDisabled}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				noOptionsMessage={() => <Text as="span">Nenhum dado encontrado.</Text>}
				loadingMessage={() => 'Buscando dados...'}
				{...rest}
			/>
		</InputWrapper>
	);
};

export default Multiselect;
