/**
 *
 * RepresentativeFilter
 *
 */

import Select from '../Select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, SystemStyleObject } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import cleanDeep from 'clean-deep';
import { useCanViewAction } from 'hooks/useCanViewAction';
import { useSession } from 'hooks/useSession';
import { useCustomToast } from 'hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { getSector } from 'services/http/sectors';
import { IPaginationParams } from 'types/pagination';
import { ISector } from 'types/sectors';
import { IOption } from 'types/select';
import { API_DEFAULT_ERROR, DATA_CONTROL_STEPS_OPTIONS, SECTOR_TYPE, USER_ROLES } from 'utils/constants';
import { ResponseErrors } from 'utils/parseErrors';
import { parsedOptionArray } from 'utils/parseOptionArray';
import { parseUrlParams } from 'utils/parseUlrParams';
import { stringifyCurrentUserUnits } from 'utils/stringifyCurrentUserUnits';
import * as yup from 'yup';

export interface RepresentativeFilterForm {
	etapaEnum?: IOption;
	setorId?: IOption<ISector>;
}

interface IRepresentativeFilterParams extends IPaginationParams, RepresentativeFilterForm {}

export type RepresentativeFilteKeys = keyof IRepresentativeFilterParams;

interface RepresentativeFilterProps {
	isListing?: boolean;
}

const RepresentativeFilter = ({ isListing = true }: RepresentativeFilterProps) => {
	const styles: Record<string, SystemStyleObject> = {
		container: {
			width: '100%',
			height: 'auto',
			border: '0.063rem solid',
			borderRadius: '0.25rem',
			borderColor: 'gray.95',
			background: 'gray.80',
			justifyContent: { base: 'center', md: 'flex-start' },
			padding: '2rem',
		},
		content: {
			width: '100%',
		},
		gridFields: {
			w: '100%',
			display: 'flex',
			flexDirection: {
				base: 'column',
				md: 'row',
			},
			gap: {
				base: '2rem',
				lg: '3rem',
			},
		},
		fields: {
			display: 'flex',
			width: '100%',
			alignItems: 'baseline',
			gap: {
				base: '2rem',
				md: '2rem',
				lg: '3rem',
			},
			flexDirection: {
				base: 'column',
				md: 'column',
				lg: 'column',
				xl: 'row',
			},
		},
		gridItemsEtapa: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '12.5rem',
			},
			alignItems: {
				base: 'start',
				md: 'end',
			},
		},
		gridItemsSetor: {
			width: '100%',
			maxWidth: {
				base: '100%',
				md: '17rem',
			},
		},
		buttonSubmit: {
			width: '100%',
		},
		button: {
			mt: '2rem',
			width: { base: '100%', md: '100%', lg: '100', xl: '15rem' },
		},
	};

	const { addToast } = useCustomToast();
	const {
		session: { user },
	} = useSession();
	const [searchParams, setParams] = useSearchParams();

	const optionSchema = yup
		.object()
		.shape({
			label: yup.string().required('Este campo é obrigatório.'),
			value: yup.string().required('Este campo é obrigatório.'),
		})
		.required('Este campo é obrigatório.')
		.default(undefined)
		.nullable();

	const schema = yup.object().shape({
		setorId: optionSchema,
	});

	// Seta os valores nos campos e desabilita o useEffect em seguida
	const [counter, setCounter] = useState(0);

	const isPresident = useCanViewAction([USER_ROLES.PRESIDENTE]);

	const { defaultValues, params } = useMemo(() => {
		const params = parseUrlParams<RepresentativeFilteKeys>(['etapaEnum', 'setorId'], searchParams);

		const etapaEnum = DATA_CONTROL_STEPS_OPTIONS.find(item => item.value === params.etapaEnum);

		const defaultValues = {
			etapaEnum,
		};
		return { defaultValues, params };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams, isPresident]);

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		resetField,
	} = useForm<RepresentativeFilterForm>({
		resolver: !isListing ? undefined : yupResolver(schema),
		defaultValues,
	});

	const step = watch('etapaEnum');

	const selectedStep = {
		ETAPA_UM: [SECTOR_TYPE.PRESIDENT_TYPE, SECTOR_TYPE.BOARD_TYPE],
		ETAPA_DOIS: [SECTOR_TYPE.MANAGEMENT_TYPE, SECTOR_TYPE.COORDINATION],
	};

	const { data: sectors, isLoading: isSectorsLoading } = useQuery<ISector[], AxiosError<ResponseErrors>, ISector[]>(
		['sectors', step],
		() =>
			getSector(
				step?.value ? selectedStep[step?.value as keyof typeof selectedStep] : SECTOR_TYPE.ALL,
				stringifyCurrentUserUnits(user),
			),
		{
			onError: ({ response }) => {
				addToast({
					type: 'error',
					title: 'Tente novamente!',
					description: response?.data?.message || API_DEFAULT_ERROR,
				});
			},
		},
	);

	const onSubmit = (values: RepresentativeFilterForm) => {
		const parseData = cleanDeep({
			etapaEnum: String(values.etapaEnum?.value || ''),
			setorId: String(values.setorId?.value || ''),
		});
		setParams(parseData);
	};

	const clearFields = useCallback(
		(field: keyof RepresentativeFilterForm, defaultValue: string | IOption[] = '') => {
			resetField(field, { defaultValue });
		},
		[resetField],
	);

	useEffect(() => {
		const isFirstData = counter < 1 && params && sectors;
		if (isFirstData) {
			const setorId = parsedOptionArray(sectors, 'nome', 'idSetor')?.find(
				item => String(item.value) === params.setorId,
			) as IOption<ISector>;

			setValue('setorId', setorId);
			setCounter(counter + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params, sectors]);

	return (
		<>
			<Box sx={styles.container}>
				<Box sx={styles.content} as="form" onSubmit={handleSubmit(onSubmit)}>
					<Grid sx={styles.gridFields}>
						<Box sx={styles.gridItemsEtapa}>
							<Controller
								name="etapaEnum"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										label="Etapa"
										dataTestId="select--etapa"
										placeholder="Selecione a etapa"
										value={value}
										onChange={e => {
											onChange(e);
											clearFields('setorId');
										}}
										options={DATA_CONTROL_STEPS_OPTIONS}
										errorMessage={errors?.etapaEnum?.value?.message}
									/>
								)}
							/>
						</Box>

						<Box sx={styles.gridItemsSetor}>
							<Controller
								control={control}
								name="setorId"
								render={({ field: { value, onChange } }) => (
									<Select
										label="Unidade de exercício"
										placeholder="Buscar uma unidade/setor"
										required
										options={parsedOptionArray(sectors, 'nome', 'idSetor')}
										value={value}
										onChange={onChange}
										isLoading={isSectorsLoading}
										errorMessage={errors?.setorId?.message}
										dataTestId="select--sector"
									/>
								)}
							/>
						</Box>
					</Grid>
					<Box sx={styles.buttonSubmit}>
						<Button type="submit" sx={styles.button} data-testid="button--submit">
							Pesquisar
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default RepresentativeFilter;
